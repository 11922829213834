import {Component, inject, Injector, model} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Organization} from '@model/organization';
import {OrganizationService} from '@services/organization.service';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-organization-edit',
  templateUrl: './organization-edit.component.html',
  styleUrls: ['./organization-edit.component.scss'],
  imports: [FormsModule, InputTextModule, InputSwitchModule, Button, NgIf]
})
export class OrganizationEditComponent {

  organization = model<Organization>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Organization>);
  #organizationService = inject(OrganizationService);
  #injector = inject(Injector);

  constructor() {
    this.organization.set(this.config.data);
  }

  save(organization: Organization) {
    this.#organizationService.updateOrganization(organization)
      .subscribe(organization => {
        this.organization.set(organization);
        this.ref.close(organization)
      });
  }

  close() {
    this.ref.close({});
  }

}
