import { inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { filter, map, Observable, of, shareReplay } from 'rxjs';
import { Page } from '@typedefs/page';
import { environment } from '@environments/environment';
import { Pagination } from './pagination';
import { MovementDto, StockPalletDto, StockPalletSearchDto } from '@typedefs/stock-rest';
import { Movement } from '@model/movement';
import { StockPallet } from '@model/stock-pallet';
import { PalletTypeService } from '@services/pallet-type.service';
import { PalletType } from '@model/pallet-type';
import { MovementService } from "@services/movement.service";
import { PalletSearch } from "@model/search/pallet-search";

export type MovementPagination = Pagination
export type StockPalletPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class StockPalletService {

  private httpClient = inject(HttpClient);
  private palletTypeService = inject(PalletTypeService);
  private injector = inject(Injector);

  public getStockPallet$(id: number): Observable<StockPallet> {
    return this.httpClient.get<StockPalletDto>(`${environment.apiUrl}/pallets/${id}`)
      .pipe(
        map(stockPalletDto => this.mapToStockPallet(stockPalletDto)),
        shareReplay()
      );
  }

  public getStockPallets$(palletSearch: PalletSearch, pagination: StockPalletPagination): Observable<Page<StockPallet>> {
    const palletSearchDto: StockPalletSearchDto = this.mapToStockPalletSearchDto(palletSearch);
    return this.httpClient.post<Page<StockPalletDto>>(`${environment.apiUrl}/pallets/search`, palletSearchDto, {params: pagination})
      .pipe(
        map(page => {
          return {
            ...page,
            content: page.content.map(stockPalletDto => this.mapToStockPallet(stockPalletDto))
          }
        }),
        shareReplay()
      );
  }

  public getStockPalletMovements$(stockPallet: StockPallet, pagination: MovementPagination, injector: Injector): Observable<Page<Movement>> {
    // done dynamically to avoid circular dependency
    const movementService = this.injector.get(MovementService);

    return this.httpClient.get<Page<MovementDto>>(`${environment.apiUrl}/pallets/${stockPallet.id}/movements`, {params: pagination})
      .pipe(
        map(page => {
          return {
            ...page,
            content: page.content.map(movement => movementService.mapToMovement(movement, injector))
          }
        }),
        shareReplay()
      );
  }

  private mapToStockPallet(stockPalletDto: StockPalletDto): StockPallet {
    return {
      ...stockPalletDto,
      palletType$: !stockPalletDto.palletTypeId ? of(undefined) : this.palletTypeService.getPalletType$(stockPalletDto.palletTypeId)
        .pipe(filter(palletType => palletType !== undefined)) as Observable<PalletType>,
    };
  }

  public mapToStockPalletSearchDto(palletSearch: PalletSearch): StockPalletSearchDto {
    // done dynamically to avoid circular dependency
    const movementService = this.injector.get(MovementService);

    return {
      receptionId: palletSearch?.reception?.id,
      id: palletSearch.id,
      withStock: palletSearch.withStock,
      movementSearchDto: palletSearch.movementSearch ? movementService.mapToMovementSearchDto(palletSearch.movementSearch) : undefined
    };
  }
}
