import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, inject, Injector, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, map, mergeMap, Observable, shareReplay } from 'rxjs';
import { Warehouse } from "@model/warehouse";
import { StockSearch } from "@model/search/stock-search";
import { ComponentChanges } from "@util/component-change";
import { PaginationService } from "@services/pagination.service";
import { StockService } from "@services/stock.service";
import { UserService } from "@services/user.service";
import { PreparationPagination } from "@services/preparation.service";
import { ArticleCategorySearchDto } from "@typedefs/stock-rest";
import { ArticleStorageConditionSearch } from "@model/search/article-storage-condition-search";
import { activeWarehouseSearchByCompany, WarehouseSearch } from "@model/search/warehouse-search";
import { Article } from "@model/article";
import { ArticleStorageCondition } from "@model/article-storage-condition";
import { ArticleCategory } from "@model/article-category";
import { Page } from "@typedefs/page";
import { ArticleCategorySearch } from "@model/search/article-category-search";
import { ArticleSearch } from "@model/search/article-search";
import { Stock } from "@model/stock";
import { Pagination } from "@services/pagination";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { Company } from "@model/company";
import { getTableFilter } from "@util/primeng-table";
import { createSetAndRemoveDragImage } from "@util/drag-and-drop";
import { Preparation } from "@model/preparation";
import { AsyncPipe, DatePipe, DecimalPipe, NgIf } from '@angular/common';
import { PrimeTemplate } from 'primeng/api';
import { ArticleStorageConditionSelectionComponent } from '../../../article-storage-condition/article-storage-condition-selection.component';
import { ArticleMultiSelectionComponent } from '../../../article/selection/multi/article-multi-selection.component';
import { DatePickerComponent } from '../../../date/date-picker/date-picker.component';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from 'primeng/dragdrop';
import { ArticleStorageConditionComponent } from '../../../article-storage-condition/article-storage-condition.component';
import { ArticleComponent } from '../../../article/article.component';
import { PalletComponent } from '../../../pallet/pallet.component';

@Component({
    selector: 'foodbank-preparation-stock-preparation-table',
    templateUrl: './stock-preparation-table.component.html',
    styleUrl: './stock-preparation-table.component.scss',
    imports: [NgIf, TableModule, PrimeTemplate, ArticleStorageConditionSelectionComponent, ArticleMultiSelectionComponent, DatePickerComponent, TooltipModule, FormsModule, DragDropModule, ArticleStorageConditionComponent, ArticleComponent, PalletComponent, AsyncPipe, DecimalPipe, DatePipe]
})
export class StockPreparationTableComponent implements OnChanges, AfterViewInit {

  protected readonly DEFAULT_ROWS_PER_PAGE = 15;

  @Input() warehouse?: Warehouse;
  @Input() stockFilter?: StockSearch;
  @Input() preparation?: Preparation;
  @Input() tableSizeStyleClass = localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '';
  @Output() onStockSelect = new EventEmitter<Stock[]>();
  @Output() onStockDragStart = new EventEmitter<Stock[]>();
  @Output() onStockDragEnd = new EventEmitter<void>();

  emptyMessage?: string;


  // searches for stock page
  stockFilter$: BehaviorSubject<StockSearch | undefined>;
  selectedArticles$: BehaviorSubject<Article[]>;
  selectedArticleCategories$: BehaviorSubject<ArticleCategory[]>;
  selectedArticleStorageConditions$: BehaviorSubject<ArticleStorageCondition[]>;
  selectedBestBeforeDateRange$: BehaviorSubject<Date | Date[] | undefined>;

  preparation$: BehaviorSubject<Preparation | undefined>
  stockSearch$: Observable<StockSearch>;
  stockPage$: Observable<Page<Stock>>;
  pagination$: BehaviorSubject<Pagination>;
  refreshTrigger$ = new BehaviorSubject<void>(undefined);

  // searches for filters
  filterWarehouseSearch$: Observable<WarehouseSearch>;
  filterArticleSearch$: Observable<ArticleSearch>;
  filterArticleCategorySearch$: BehaviorSubject<ArticleCategorySearch>;
  filterArticleStorageConditionSearch$: BehaviorSubject<ArticleStorageConditionSearch>;

  // distributionConfig$: BehaviorSubject<DistributionConfig | undefined>;
  selectedStocks: Stock[] = [];

  selectionEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  tableHeight!: string;
  @ViewChild('tableContainer')
  tableElementRef!: ElementRef;
  #injector = inject(Injector)

  constructor(private paginationService: PaginationService,
              private stockService: StockService,
              private userService: UserService) {

    const currentUser$ = this.userService.getCurrentUser$();
    const currentUserCompany$ = currentUser$.pipe(mergeMap(user => user.company$));
    const pagination = this.paginationService.getDefaultPagination(15);
    this.pagination$ = new BehaviorSubject<PreparationPagination>(pagination);
    this.stockFilter$ = new BehaviorSubject<StockSearch | undefined>(undefined);
    this.preparation$ = new BehaviorSubject<Preparation | undefined>(undefined);

    this.filterArticleCategorySearch$ = new BehaviorSubject<ArticleCategorySearchDto>({});
    this.filterArticleStorageConditionSearch$ = new BehaviorSubject<ArticleStorageConditionSearch>({});

    this.filterArticleSearch$ = this.initializeArticleSearch$({
      stockSearch$: this.stockFilter$,
      articleCategorySearch$: this.filterArticleCategorySearch$,
      articleStorageConditionSearch$: this.filterArticleStorageConditionSearch$,
      company$: currentUserCompany$,
      preparation$: this.preparation$
    });

    this.selectedArticles$ = new BehaviorSubject<Article[]>([]);
    this.selectedArticleCategories$ = new BehaviorSubject<ArticleCategory[]>([]);
    this.selectedArticleStorageConditions$ = new BehaviorSubject<ArticleStorageCondition[]>([]);
    this.selectedBestBeforeDateRange$ = new BehaviorSubject<Date | Date[] | undefined>(undefined);

    this.filterWarehouseSearch$ = currentUserCompany$.pipe(
      map(currentUserCompany => activeWarehouseSearchByCompany(currentUserCompany))
    );

    this.stockSearch$ = this.initializeStockSearch$({
      stockSearch$: this.stockFilter$,
      articles$: this.selectedArticles$,
      articleCategories$: this.selectedArticleCategories$,
      articleStorageConditions$: this.selectedArticleStorageConditions$,
      bestBeforeDate$: this.selectedBestBeforeDateRange$,
      preparation$: this.preparation$
    });

    this.stockFilter$.next(this.stockFilter);

    this.stockPage$ = combineLatest([this.pagination$, this.stockSearch$, this.refreshTrigger$]).pipe(
      debounceTime(10),
      mergeMap(([pagination, stockSearch, _]) => this.stockService.findStock(stockSearch, pagination, this.#injector)),
      shareReplay()
    );
  }

  private initializeStockSearch$(stockSearchInputs: {
    stockSearch$: Observable<StockSearch | undefined>,
    articles$: Observable<Article[]>,
    articleCategories$: Observable<ArticleCategory[]>,
    articleStorageConditions$: Observable<ArticleStorageCondition[]>,
    bestBeforeDate$: Observable<Date | Date[] | undefined>,
    preparation$: Observable<Preparation | undefined>
  }): Observable<StockSearch> {
    return combineLatest([
      stockSearchInputs.stockSearch$,
      stockSearchInputs.articles$,
      stockSearchInputs.articleCategories$,
      stockSearchInputs.articleStorageConditions$,
      stockSearchInputs.bestBeforeDate$,
      stockSearchInputs.preparation$
    ]).pipe(
      map(([stockFilter, articles, articleCategories,
             articleStorageConditions, selectedBestBeforeDateRange, preparation]) => ({
        ...stockFilter,
        minBestBeforeDate: this.getMinBestBeforeDate(selectedBestBeforeDateRange),
        maxBestBeforeDate: this.getMaxBestBeforeDate(selectedBestBeforeDateRange),
        articleSearch: {
          articles: articles,
          articleCategorySearch: {
            articleCategories: articleCategories
          },
          articleStorageConditionSearch: {
            articleStorageConditions: articleStorageConditions
          }
        },
        excludedPreparation: preparation
      })));
  }

  private initializeArticleSearch$(filterArticleSearchInputs$: {
    stockSearch$: Observable<StockSearch | undefined>,
    articleCategorySearch$: Observable<ArticleCategorySearch>,
    articleStorageConditionSearch$: Observable<ArticleStorageConditionSearch>,
    company$: Observable<Company>,
    preparation$: Observable<Preparation | undefined>
  }): Observable<ArticleSearch> {
    return combineLatest([
      filterArticleSearchInputs$.stockSearch$,
      filterArticleSearchInputs$.articleCategorySearch$,
      filterArticleSearchInputs$.articleStorageConditionSearch$,
      filterArticleSearchInputs$.company$,
      filterArticleSearchInputs$.preparation$
    ]).pipe(
      map(([stockFilter, articleCategorySearch, articleStorageConditionSearch, currentUserCompany, preparation]) => ({
        articleCategorySearch: articleCategorySearch,
        articleStorageConditionSearch: articleStorageConditionSearch,
        stockSearch: {
          ...stockFilter,
          warehouseSearch: activeWarehouseSearchByCompany(currentUserCompany),
          excludedPreparation: preparation
        }
      })));
  }

  getMinBestBeforeDate(selectedBestBeforeDateRange: Date | Date[] | undefined): Date | undefined {
    if (Array.isArray(selectedBestBeforeDateRange)) {
      return selectedBestBeforeDateRange[0];
    }

    return undefined;
  }

  getMaxBestBeforeDate(selectedBestBeforeDateRange: Date | Date[] | undefined): Date | undefined {
    if (Array.isArray(selectedBestBeforeDateRange)) {
      return selectedBestBeforeDateRange.length === 2 ? selectedBestBeforeDateRange[1] : undefined;
    }

    return selectedBestBeforeDateRange;
  }

  ngAfterViewInit() {
    this.updateTableHeight();
  }

  ngOnChanges(changes: ComponentChanges<StockPreparationTableComponent>) {
    if (changes.stockFilter && this.stockFilter$) {
      this.stockFilter$.next(changes.stockFilter.currentValue);
    }
    if (changes.preparation && this.preparation$) {
      this.preparation$.next(changes.preparation.currentValue);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateTableHeight();
  }

  updateTableHeight() {
    const tableOffsetTop = this.tableElementRef.nativeElement.getBoundingClientRect().top || 289;
    const windowHeight = window.innerHeight;
    const availableHeight = windowHeight - tableOffsetTop - 180; // Adjust for footer
    this.tableHeight = `${availableHeight}px`;
  }

  stockIdentity(stock: any): Stock {
    return stock;
  }

  loadStockList(event: TableLazyLoadEvent) {
    const articles: Article[] = getTableFilter(event, 'articles');
    this.selectedArticles$.next(articles);

    const articleStorageConditions: ArticleStorageCondition[] = getTableFilter(event, 'storageConditions');
    this.selectedArticleStorageConditions$.next(articleStorageConditions);

    const bestBeforeDate: Date | Date[] | undefined = getTableFilter(event, 'bestBeforeDate');
    this.selectedBestBeforeDateRange$.next(bestBeforeDate);

    const pagination = this.paginationService.getTablePagination(event);
    this.pagination$.next(pagination);
  }

  notifySelectionChange(stock: Stock[]) {
    this.selectedStocks = stock;
    this.onStockSelect.emit(stock);
  }

  isStockDraggable(stock: Stock): boolean {
    if (!this.selectionEnabled$.getValue()) {
      return false;
    }

    return !this.selectedStocks || this.selectedStocks.length === 0 || this.selectedStocks.includes(stock);
  }

  notifyStockDragStart(event: DragEvent, stock: Stock) {
    const value = this.selectedStocks.length === 0
      ? [stock]
      : this.selectedStocks;
    this.onStockDragStart.emit(value);

    const selectedPallets = this.selectedStocks.length === 0 ? 1 : this.selectedStocks.length
    const text = `Moving ${selectedPallets} pallet${selectedPallets > 1 ? 's' : ''}`;

    createSetAndRemoveDragImage(event, text);
  }

  notifyStockDragEnd(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.dropEffect !== 'none') {
      this.selectedStocks = [];
    }
    this.onStockDragEnd.emit();
  }
}
