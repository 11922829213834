import {BehaviorSubject, map, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {LanguageCode, LanguageInfo} from '@model/languageInfo';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private LANGUAGES: LanguageInfo[] = [
    {code: 'nl', label: 'Nederlands', icon: 'fi fi-nl', language: 'DUTCH'},
    {code: 'fr', label: 'Français', icon: 'fi fi-fr', language: 'FRENCH'},
    // {code: 'en', label: 'English', icon: 'fi fi-gb', language: 'ENGLISH'},
    // {code: 'de', label: 'Deutsch', icon: 'fi fi-de', language: 'GERMAN'},
  ]

  private language$ = new BehaviorSubject<LanguageInfo>(this.LANGUAGES[0]);

  getLanguageOptions(): LanguageInfo[] {
    return this.LANGUAGES;
  }

  getLanguage$() {
    return this.language$.asObservable();
  }

  setLanguage(languageCode: string) {
    const language = this.LANGUAGES.find(language => language.code === languageCode);
    if (!language) {
      throw new Error(`Language with code ${languageCode} not found`);
    }

    this.language$.next(language);
  }

  isCurrentLanguageDisplayable$(): Observable<boolean> {
    const languageCodes = this.LANGUAGES.map(language => language.code);
    return this.isCurrentLanguageInCodes$(languageCodes);
  }

  isCurrentLanguageInCodes$(languageCodes: LanguageCode[]): Observable<boolean> {
    return this.getLanguage$()
      .pipe(map(language => languageCodes.includes(language.code))
      );

  }

  getCurrentLanguage(): LanguageInfo {
    return this.language$.getValue();
  }
}
