<p-dropdown [ngModel]="ngModel"
            (onChange)="select($event.value)"
            [options]="(organizations$ | async) || []"
            optionLabel="name"
            placeholder="None"
            [filter]="true"
            filterMatchMode="contains"
            filterBy="id,name"
            (onClear)="clear()"
            [showClear]="true"
            [loading]="loading"
            dataKey="id"
            appendTo="body">
    <ng-template pTemplate="selectedItem">
        <foodbank-organization ngDefaultControl [ngModel]="ngModel" [showId]="true" />
    </ng-template>
    <ng-template let-organization pTemplate="item">
        <foodbank-organization ngDefaultControl [ngModel]="organization" [showId]="true" />
    </ng-template>
</p-dropdown>
