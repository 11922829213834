<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">

  <p-panel class="pl-0 md:col-4 panel-header-white" [style]="{ 'min-width': '550px' }" [class.bulk-movements-inactive]="!bulkMovementActive()">
    <ng-template pTemplate="header">
      <div class="ml-1 gap-1" *ngIf="bulkMovementActive()">
        <i class="pi pi-truck mr-2"></i>
        <span class="font-bold">Bulk movements settings</span>
      </div>
    </ng-template>
    <div class="p-2 fadeinup animation-duration-100 animation-iteration-1 transition-linear" *ngIf="bulkMovementActive()">
      <div class="flex field justify-content-left align-items-center">
        <label class="w-4 mb-0 mr-2" for="movementType">Movement</label>
        <foodbank-movement-type-single-selection
          ngDefaultControl
          id="movementType"
          [(ngModel)]="bulkMovementTypeOverride"
          [movementTypeSearch]="bulkMovementTypeSearch()"
          [excludeMovementKinds]="['RECEIPT_CORRECTION','INTER_WAREHOUSE_TRANSFER', 'ARTICLE_CHANGE', 'FROZEN_EXPEDITION']"
        />
      </div>

      <div class="flex field justify-content-left align-items-center">
        <label class="w-4 mb-0 mr-2" for="warehouse">Warehouse *</label>
        <foodbank-warehouse-selection
          ngDefaultControl
          id="warehouse"
          name="warehouse"
          [(ngModel)]="bulkMovementWarehouseOverride"
          [warehouseSearch]="bulkMovementWarehouseSearch()"
        />
      </div>

      <div class="flex field justify-content-left align-items-center" *ngIf="bulkMovementNeedsSupplier()">
        <label class="w-4 mb-0 mr-2" for="supplier">Supplier *</label>
        <foodbank-supplier-selection
          ngDefaultControl
          id="supplier"
          name="supplier"
          [(ngModel)]="bulkMovementSupplier"
          [supplierSearch]="bulkMovementSupplierSearch()"
        />
      </div>

      <div class="flex field justify-content-left align-items-center" *ngIf="bulkMovementNeedsOrganization()">
        <label class="w-4 mb-0 mr-2" for="supplier">Organization *</label>
        <foodbank-organization-selection
          ngDefaultControl
          id="organization"
          name="organization"
          [(ngModel)]="bulkMovementOrganization"
        />
      </div>

      <div class="flex field justify-content-left align-items-center" *ngIf="bulkMovementNeedsTargetWarehouse()">
        <label class="w-4 mb-0 mr-2" for="warehouse">Target warehouse *</label>
        <foodbank-warehouse-selection
          id="targetWarehouse"
          name="targetWarehouse"
          [(ngModel)]="bulkMovementTargetWarehouse"
          [warehouseSearch]="bulkMovementTargetWarehouseSearch()"
          ngDefaultControl/>
      </div>

      <div class="flex field justify-content-left align-items-center">
        <label class="w-4 mb-0 mr-2" for="date">Date</label>
        <foodbank-date-picker
          id="date"
          name="date"
          [(ngModel)]="bulkMovementDate"
          [showSelectionOptions]="false"
          ngDefaultControl/>
      </div>
    </div>
    <div class="flex flex-column md:flex-row md:justify-content-left md:align-items-center" *ngIf="bulkMovementActive()">
      @if (canExitBulkMovementMode()) {
        <p-toggleButton [(ngModel)]="bulkMovementActive" offIcon="pi pi-truck" onIcon="pi pi-sign-out" offLabel="Enter bulk movements mode" onLabel="Exit bulk movements mode"/>
      }
    </div>
  </p-panel>
</div>
<p-table #dt
         [value]="articlePage()?.content || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="(articlePage()?.totalElements || 0)"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [loading]="bulkMovementNotReady()"
         loadingIcon="pi pi-spin pi-spinner big-loading-icon"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [multiSortMeta]="[{ field: 'ARTICLE_CATEGORY_ID', order: 1 }, { field: 'ID', order: 1 }]"
         [autoLayout]="true"
         dataKey="id"
         sortMode="multiple">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 *ngIf="!bulkMovementActive()" class="m-0">Articles</h2>
      <h2 *ngIf="bulkMovementActive() && !bulkMovementValid()" class="m-0">Please provide all required bulk movement settings</h2>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="ID">
        <span i18n>#</span>
        <p-sortIcon field="ID"/>
      </th>
      <th pSortableColumn="NAME">
        <span i18n>Name</span>
        <p-sortIcon field="NAME"/>
      </th>
      <th *ngIf="bulkMovementActive()">Stock (Kg)</th>
      <th *ngIf="bulkMovementActive()">Quantity (Kg)</th>
      <th pSortableColumn="ARTICLE_CATEGORY_ID">
        <span i18n>Category</span>
        <p-sortIcon field="ARTICLE_CATEGORY_ID"/>
      </th>
      <th pSortableColumn="ARTICLE_STORAGE_CONDITION_ID">
        <span i18n>Storage</span>
        <p-sortIcon field="ARTICLE_STORAGE_CONDITION_ID"/>
      </th>
      <th>
        <span i18n>Bulk food</span>
      </th>
    </tr>
    <tr>
      <th colspan="2">
        <p-columnFilter field="articles" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-article-multi-selection ngDefaultControl
                                              [selectedArticles]="articlesFilter()"
                                              (onArticleSelected)="articlesFilter.set($event)"
                                              [articleSearch]=filterArticlesSearch()
            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="bulkMovementActive()"></th>
      <th *ngIf="bulkMovementActive()"></th>
      <th>
        <p-columnFilter field="categories" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-article-category-selection [selectedArticleCategories]="this.articleCategoriesFilter()"
                                                 (onArticleCategorySelected)="this.articleCategoriesFilter.set($event)"
                                                 [articleCategorySearch]="filterArticleCategorySearch()"
            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="storageConditions" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-article-storage-condition-selection [selectedArticleStorageConditions]="this.articleStorageConditionsFilter()"
                                                          (onArticleStorageConditionSelected)="this.articleStorageConditionsFilter.set($event)"
                                                          [articleStorageConditionSearch]="filterArticleStorageConditionSearch()"
            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-checkbox [binary]="true" [(ngModel)]="bulkFood"/>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-articleWithStockSummary>
    <ng-container *ngIf="fixTyping(articleWithStockSummary) as articleWithQuantity">
      @let article = articleWithQuantity.article;
      @let stockSummary = articleWithQuantity.stockSummary;
      <tr>
        <td>
          <ng-container *ngIf="bulkMovementActive()">
            {{ article.id }}
          </ng-container>
          <ng-container *ngIf="!bulkMovementActive()">
            <a (click)="openEditDialog(article)">{{ article.id }}</a>
          </ng-container>
        </td>
        <td class="white-space-normal">
          <foodbank-article ngDefaultControl [ngModel]="article" [showId]="false"/>
        </td>
        <td *ngIf="bulkMovementActive()" class="quantity aligned-quantity">
          <foodbank-number [value]="stockSummary.totalWeight"/>
        </td>
        <td *ngIf="bulkMovementActive()" class="align-items-center">
          <input #quantityInput pInputText type="number" min="0" placeholder="Quantity" class="w-full sm:w-auto" [disabled]="!bulkMovementValid()" (keyup.enter)="saveArticleQuantity(articleWithQuantity, +quantityInput.value); quantityInput.value = '';"/>
          <p-button pTooltip="This will move selected quantity."
                    icon="pi pi-check"
                    [text]="true"
                    (onClick)="saveArticleQuantity(articleWithQuantity, +quantityInput.value); quantityInput.value = '';"
                    [disabled]="+quantityInput.value <= 0 || !bulkMovementValid()"
                    class="p-button-rounded p-button-text ml-2"/>
        </td>
        <td>
          <foodbank-article-category [articleCategory]="article.articleCategory$ | async"/>
        </td>
        <td>
          <foodbank-article-storage-condition [articleStorageCondition]="article.articleStorageCondition$ | async"/>
        </td>
        <td>
          <p-checkbox [binary]="true" [ngModel]="article.bulkFood" [disabled]="true"/>
        </td>
        @if (!bulkMovementActive()) {
          <td>
            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(article)"></button>
            <p-button *ngIf="article.fead"
                      (click)="openFeadCampaignDialog(article)"
                      label="{{article.feadYear}}"
                      icon="pi pi-calendar"
                      [disabled]="bulkMovementActive()"
                      styleClass="p-button p-button-rounded p-button-text"/>
          </td>
        }
      </tr>
    </ng-container>
  </ng-template>
</p-table>
