import {OrganizationSearchDto} from '@typedefs/stock-rest';
import {Warehouse} from '@model/warehouse';
import {Bank} from '@model/bank';
import {PreparationItemSearch} from '@model/search/preparation-item-search';
import { SafeOmit } from "@model/mapping-utils";
import { Company } from "@model/company";
import { WarehouseSearch } from "@model/search/warehouse-search";

export interface OrganizationSearch extends SafeOmit<OrganizationSearchDto, 'companyId' | 'bankId' | 'warehouseId' | 'pickupWarehouseId'> {
  name?: string;
  zip?: string;
  city?: string;
  bank?: Bank;
  warehouse?: Warehouse;
  company?: Company;
  pickupWarehouse?: Warehouse;
  preparationItemSearch?: PreparationItemSearch;
}

export function organizationSearchByCompany(company: Company): WarehouseSearch {
  return {
    company
  };
}
