/// <reference types="@angular/localize" />

import {ConfirmationService, MessageService, SharedModule} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AppRoutingModule} from './app/app-routing.module';
import {AppLayoutModule} from '@components/layout/app.layout.module';
import {OAuthModule} from 'angular-oauth2-oidc';
import {environment} from '@environments/environment';
import {MultiSelectModule} from 'primeng/multiselect';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {RippleModule} from 'primeng/ripple';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DialogModule} from 'primeng/dialog';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {HotkeyModule} from 'angular2-hotkeys';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {NgxBarcode6Module} from 'ngx-barcode6';
import {TabViewModule} from 'primeng/tabview';
import {CalendarModule} from 'primeng/calendar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DividerModule} from 'primeng/divider';
import {NgxPrintModule} from 'ngx-print';
import {PanelModule} from 'primeng/panel';
import {FieldsetModule} from 'primeng/fieldset';
import {DragDropModule} from 'primeng/dragdrop';
import {AutoFocusModule} from 'primeng/autofocus';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {BlockUIModule} from 'primeng/blockui';
import {ContextMenuModule} from 'primeng/contextmenu';
import {ToastModule} from 'primeng/toast';
import {ChartModule} from 'primeng/chart';
import {ChipModule} from 'primeng/chip';
import {AppComponent} from './app/app.component';
import {importProvidersFrom} from '@angular/core';
import {provideHttpClient, withInterceptors} from "@angular/common/http";

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, FontAwesomeModule, /* usage: https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/icon-library.md */ AppRoutingModule, AppLayoutModule, OAuthModule.forRoot({
      resourceServer: {
        customUrlValidation: url => url.startsWith(environment.apiUrl) && !url.startsWith(environment.apiUrl + '/config/oidc'),
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true
      }
    }), MultiSelectModule, SharedModule, FormsModule, CommonModule, ButtonModule, InputTextModule, InputSwitchModule, MultiSelectModule, RippleModule, TableModule, TooltipModule, OverlayPanelModule, DialogModule, CardModule, DropdownModule, CheckboxModule, ConfirmDialogModule, HotkeyModule.forRoot(), TriStateCheckboxModule, SelectButtonModule, NgxBarcode6Module, TabViewModule, CalendarModule, RadioButtonModule, DividerModule, NgxPrintModule, PanelModule, FieldsetModule, DragDropModule, AutoFocusModule, ToggleButtonModule, BlockUIModule, ContextMenuModule, ToastModule, ChartModule, ChipModule),
    ConfirmationService,
    MessageService,
    DialogService,
    provideHttpClient(
      withInterceptors([]),
    ),
  ]
})
  .catch(err => console.error(err));
