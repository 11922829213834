<p-dropdown [(ngModel)]="ngModel"
            (onChange)="select($event.value)"
            [options]="campaigns()"
            [disabled]="loading"
            [placeholder]="loading ? 'Loading...' : 'Select Campaign'"
            appendTo="body">
  <ng-template let-campaign pTemplate="selectedItem">
    <ng-container *ngIf="campaign">{{ campaign.name}}</ng-container>
  </ng-template>
  <ng-template let-campaign pTemplate="item">{{campaign.name}}</ng-template>
</p-dropdown>
