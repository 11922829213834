import { Injectable, Injector } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { combineLatest, forkJoin, map, Observable, shareReplay, switchMap } from 'rxjs';
import { Page } from '@typedefs/page';
import { environment } from '@environments/environment';
import { Pagination } from './pagination';
import { PreparationItemDto, PreparationItemSearchDto } from '@typedefs/stock-rest';
import { PreparationService } from '@services/preparation.service';
import { OrganizationService } from '@services/organization.service';
import { PreparationItemSearch } from '@model/search/preparation-item-search';
import { PreparationItem } from '@model/preparation-item';
import { StockService } from '@services/stock.service';
import { ArticleService } from '@services/article.service';
import { PaginationService } from '@services/pagination.service';

export type PreparationItemPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class PreparationItemService {

  constructor(private httpClient: HttpClient,
              private preparationService: PreparationService,
              private organizationService: OrganizationService,
              private stockService: StockService,
              private articleService: ArticleService,
              private paginationService: PaginationService) {
  }

  public find(preparationItemSearch: PreparationItemSearch, pagination: PreparationItemPagination, injector: Injector): Observable<Page<PreparationItem>> {
    const preparationItemSearchDto = this.mapToPreparationItemSearchDto(preparationItemSearch);
    const preparationItems = this.httpClient.post<Page<PreparationItemDto>>(`${environment.apiUrl}/preparations/items/search`, preparationItemSearchDto, {params: pagination});
    return this.paginationService.mapResults(preparationItems, preparationItemDto => this.mapToPreparationItem(preparationItemDto, injector))
      .pipe(shareReplay(1));
  }

  savePreparationItem$(preparationItem: PreparationItem, injector: Injector): Observable<PreparationItem> {
    const preparationItemDto$ = this.mapToPreparationItemDto$(preparationItem);
    return combineLatest([preparationItemDto$, preparationItem.preparation$]).pipe(
      switchMap(([preparationItemDto, preparation]) => this.httpClient.post<PreparationItemDto>(`${environment.apiUrl}/preparations/${preparation.id}/items`, preparationItemDto)),
      map(preparationItemDto => this.mapToPreparationItem(preparationItemDto, injector))
    );
  }

  public mapToPreparationItem(preparationItemDto: PreparationItemDto, injector: Injector): PreparationItem {
    return {
      ...preparationItemDto,
      preparation$: this.preparationService.getPreparation$(preparationItemDto.preparationId),
      organization$: this.organizationService.getOrganization$(preparationItemDto.organizationId),
      stock$: this.stockService.getStock$(preparationItemDto.stockId, injector),
      article$: this.articleService.getArticle$(preparationItemDto.articleId),
    };
  }

  private mapToPreparationItemDto$(preparationItem: PreparationItem): Observable<PreparationItemDto> {
    const preparation$ = preparationItem.preparation$;
    const stock$ = preparationItem.stock$;
    const article$ = preparationItem.article$;
    const organization$ = preparationItem.organization$;
    return forkJoin([preparation$, stock$, article$, organization$]).pipe( // what if the observables are not complete ?
      map(([preparation, stock, article, organization]) => ({
        rank: preparationItem.rank,
        preparationId: preparation.id,
        organizationId: organization.id,
        stockId: stock.id,
        quantity: preparationItem.quantity,
        dispatchParcelCount: preparationItem.dispatchParcelCount,
        dispatchUnitCount: preparationItem.dispatchUnitCount,
        dispatchWeight: preparationItem.dispatchWeight,
        numberOfUnits: preparationItem.numberOfUnits,
        unitWeight: preparationItem.unitWeight,
        articleId: article.id,
        dispatchedQuantity: preparationItem.dispatchedQuantity,
        palletId: preparationItem.palletId,
        fullDescription: preparationItem.fullDescription,
      }))
    )
  }

  public mapToPreparationItemSearchDto(preparationItemSearch: PreparationItemSearch): PreparationItemSearchDto {
    return {
      palletId: preparationItemSearch.palletId,
      organizationId: preparationItemSearch.organization?.id,
      stockId: preparationItemSearch.stock?.id,
      articleId: preparationItemSearch.article?.id,
      preparationId: preparationItemSearch.preparation?.id,
    }
  }
}
