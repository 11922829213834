import {Component, computed, model, signal, Signal, WritableSignal} from '@angular/core';
import {FeadPlanningSearch} from '@model/search/fead-planning-search';
import {FeadCampaignSelectionComponent} from '../../fead-campaign/fead-campaign-selection/fead-campaign-selection.component';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {FeadPlanningSummaryTableComponent} from './table/fead-planning-summary-table.component';
import {WeekFormatDirective} from "@directives/week.format.directive";
import {WeekValidatorDirective} from "@directives/week.validator.directive";
import {FeadCampaignIdentifier} from "@model/fead-campaign-identifier";

@Component({
  selector: 'foodbank-fead-planning-summary',
  templateUrl: './fead-planning-summary.component.html',
  styleUrls: ['./fead-planning-summary.component.scss'],
  imports: [FeadCampaignSelectionComponent, FormsModule, InputTextModule, WeekFormatDirective, WeekValidatorDirective, CheckboxModule, FeadPlanningSummaryTableComponent]
})
export class FeadPlanningSummaryComponent {

  feadPlanningSearch: Signal<FeadPlanningSearch>;

  campaign = signal<FeadCampaignIdentifier | undefined>(undefined);
  startPeriod: WritableSignal<string>;
  endPeriod: WritableSignal<string>;
  relevantOnly = signal(true);

  constructor() {
    const currentYear = new Date().getFullYear();
    this.startPeriod = signal(`${currentYear}/01`);
    this.endPeriod = signal(`${currentYear}/52`);

    this.feadPlanningSearch = computed(() => ({
      year: this.campaign()?.year ?? currentYear,
      startPeriod: this.startPeriod(),
      endPeriod: this.endPeriod(),
    }));
  }

}
