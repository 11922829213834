import { Component, computed, effect, forwardRef, inject, Injector, input, Input, OnChanges, OnInit, Signal } from '@angular/core';
import { Movement } from '@model/movement';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { MovementPagination, MovementService } from '@services/movement.service';
import { MovementSearch } from '@model/search/movement-search';
import { PaginationService } from '@services/pagination.service';
import { Page } from '@typedefs/page';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { Pagination } from '@services/pagination';
import { StockPallet } from "@model/stock-pallet";
import { MovementType } from "@model/movement-type";
import { DatePickerComponent, FoodbankDatePickerEvent } from "@components/date/date-picker/date-picker.component";
import { DateSearch } from "@model/search/date-search";
import { Article } from "@model/article";
import { ArticleCategory } from "@model/article-category";
import { Supplier } from "@model/supplier";
import { Organization } from "@model/organization";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { MovementDialogComponent } from "@components/movement/new-movement-dialog/movement-dialog.component";
import { ComponentChanges } from "@util/component-change";
import { WarehouseSearch, warehouseSearchByCompany } from "@model/search/warehouse-search";
import { Warehouse } from "@model/warehouse";
import { OrganizationSearch, organizationSearchByCompany } from "@model/search/organization-search";
import { UserService } from "@services/user.service";
import { PalletSearch } from "@model/search/pallet-search";
import { toSignal } from "@angular/core/rxjs-interop";
import { AsyncPipe, DatePipe, DecimalPipe, NgForOf, NgIf } from "@angular/common";
import { MultiSelectModule } from "primeng/multiselect";
import { FormsModule } from "@angular/forms";
import { ArticleCategorySingleSelectionComponent } from "@components/article-category/selection/single/article-category-single-selection.component";
import { PalletSingleSelectionComponent } from "@components/pallet/selection/single/pallet-single-selection.component";
import { ArticleSingleSelectionComponent } from "@components/article/selection/single/article-single-selection.component";
import { MovementTypeMultiSelectionComponent } from "@components/movement-type/selection/multi/movement-type-multi-selection.component";
import { WarehouseSingleSelectionComponent } from "@components/warehouse/selection/single/warehouse-single-selection.component";
import { SupplierSelectionComponent } from "@components/supplier/selection/single/supplier-selection.component";
import { OrganizationSingleSelectionComponent } from "@components/organization/selection/single/organization-single-selection.component";
import { PalletComponent } from "@components/pallet/pallet.component";
import { ArticleComponent } from "@components/article/article.component";
import { MovementTypeComponent } from "@components/movement-type/movement-type.component";
import { WarehouseComponent } from "@components/warehouse/warehouse.component";
import { UserComponent } from "@components/users/user.component";
import { OrganizationComponent } from "@components/organization/organization.component";
import { InputTextModule } from "primeng/inputtext";
import { SupplierComponent } from "@components/supplier/supplier.component";
import { overrideSignal } from "@util/foodbanks-signal-rxjs-interop";

type ColumnConfig<T> = {
  field: keyof Required<T>;
  label: string;
  visible: boolean;
}

type ColumnsConfig<T> = {
  [K in keyof Partial<T>]: ColumnConfig<T>;
}

type MovementColumnsConfig = ColumnsConfig<Movement>

const FOODBANK_PREFERENCES_MOVEMENTS_LIST_COLUMNS = 'FOODBANK_PREFERENCES_MOVEMENTS_LIST_COLUMNS';

@Component({
    selector: 'foodbank-movement-list',
    templateUrl: './movement-list.component.html',
    styleUrl: './movement-list.component.scss',
    imports: [
        TableModule,
        AsyncPipe,
        MultiSelectModule,
        FormsModule,
        ArticleCategorySingleSelectionComponent,
        forwardRef(() => PalletSingleSelectionComponent),
        ArticleSingleSelectionComponent,
        DatePickerComponent,
        NgIf,
        MovementTypeMultiSelectionComponent,
        WarehouseSingleSelectionComponent,
        SupplierSelectionComponent,
        OrganizationSingleSelectionComponent,
        PalletComponent,
        ArticleComponent,
        DatePipe,
        MovementTypeComponent,
        DecimalPipe,
        WarehouseComponent,
        UserComponent,
        OrganizationComponent,
        InputTextModule,
        NgForOf,
        SupplierComponent
    ]
})
export class MovementListComponent implements OnInit, OnChanges {

  static readonly DEFAULT_COLUMN_CONFIG: MovementColumnsConfig = {
    stockPallet$: {label: 'Pallet', field: 'stockPallet$', visible: true},
    article$: {label: 'Article', field: 'article$', visible: true},
    date: {label: 'Date', field: 'date', visible: true},
    movementType$: {label: 'Type', field: 'movementType$', visible: true},
    quantity: {label: 'Quantity', field: 'quantity', visible: true},
    warehouse$: {label: 'Warehouse', field: 'warehouse$', visible: true},
    supplier$: {label: 'Supplier', field: 'supplier$', visible: true},
    internalBatchName: {label: 'Internal batch', field: 'internalBatchName', visible: true},
    supplierBatchName: {label: 'Supplier batch', field: 'supplierBatchName', visible: true},
    deliveryTicket: {label: 'Delivery ticket', field: 'deliveryTicket', visible: true},
    user$: {label: 'User', field: 'user$', visible: true},
    organization$: {label: 'Organization', field: 'organization$', visible: false}
  };

  @Input()
  pickedDate?: Date;

  @Input()
  movementSearch?: MovementSearch;

  @Input()
  printMode: boolean = false;

  showColumnConfig = input(false);

  allColumnsWithDefaultConfig: Signal<ColumnConfig<Movement>[]>;
  selectedColumns: Signal<ColumnConfig<Movement>[]>;
  columnsConfig:  Signal<MovementColumnsConfig>;
  expeditionMovementTypeSelected: Signal<boolean | undefined>;

  rowsPerPage = 25;
  pagination$: BehaviorSubject<Pagination>;

  movementPage$: Observable<Page<Movement>>;
  loading$ = new BehaviorSubject(true);
  movementSearch$ = new BehaviorSubject<MovementSearch | undefined>(undefined);
  restrictedMovementSearch$: Observable<MovementSearch>;

  dateSearch!: DateSearch;

  minDate?: Date;
  maxDate?: Date;

  @Input()
  filter = false;

  warehouseSearch$: Observable<WarehouseSearch | undefined>;
  organizationSearch$: Observable<OrganizationSearch>;
  palletSearch$: Observable<PalletSearch>;

  supplierBatchNameContains: string = '';
  internalBatchNameContains: string = '';
  deliveryTicketContains: string = '';

  ref: DynamicDialogRef | undefined;

  private refreshTrigger$ = new BehaviorSubject<void>(undefined);

  private dialogService = inject(DialogService);
  private paginationService = inject(PaginationService);
  private movementService: MovementService = inject(MovementService);
  private userService: UserService = inject(UserService);

  #injector = inject(Injector);

  constructor() {
    const currentUser = this.userService.getCurrentUser();
    const userPreferencesForMovementListColumns = this.userService.getCurrentUserPreferences<MovementColumnsConfig>(FOODBANK_PREFERENCES_MOVEMENTS_LIST_COLUMNS, MovementListComponent.DEFAULT_COLUMN_CONFIG);
    this.allColumnsWithDefaultConfig = computed(() => Object.values(MovementListComponent.DEFAULT_COLUMN_CONFIG));
    const movementListColumnsFromPreferences = computed(() => this.showColumnConfig()
      ? Object.values(userPreferencesForMovementListColumns()).filter(column => column.visible)
      : this.allColumnsWithDefaultConfig()
    )
    this.selectedColumns = overrideSignal(movementListColumnsFromPreferences)

    // update config based on selected columns to display
    this.columnsConfig = computed<MovementColumnsConfig>(() => this.allColumnsWithDefaultConfig().reduce((acc, column) => {
      column.visible = this.selectedColumns().some(selectedColumn => selectedColumn.field === column.field);
      return {...acc, [column.field]: column};
    }, {} as MovementColumnsConfig));

    effect(() => {
      if (currentUser()) {
        // could probably moved into a function called onChange by multiSelect
        this.userService.setUserPreferences(currentUser()!, FOODBANK_PREFERENCES_MOVEMENTS_LIST_COLUMNS, this.columnsConfig());
      }
    });

    const pagination = this.getPagination(this.paginationService.getDefaultPagination(this.rowsPerPage));
    this.pagination$ = new BehaviorSubject<MovementPagination>(pagination);
    const currentUserCompany$ = this.userService.getCurrentUserCompany$();

    this.restrictedMovementSearch$ = combineLatest([this.movementSearch$, currentUserCompany$]).pipe(
      map(([userMovementSearch, currentUserCompany]) => ({
        ...userMovementSearch,
        warehouseSearch: warehouseSearchByCompany(currentUserCompany)
      })),
      shareReplay()
    );

    this.warehouseSearch$ = currentUserCompany$.pipe(
      map(company => warehouseSearchByCompany(company))
    );
    this.organizationSearch$ = currentUserCompany$.pipe(
      map(company => organizationSearchByCompany(company)) //should it be by active company?
    );
    this.palletSearch$ = this.restrictedMovementSearch$.pipe(
      map(movementSearch => ({
        movementSearch: {
          ...movementSearch,
          pallet: undefined // remove pallet from search so we can search for all pallets even if a pallet is selected
        }
      }))
    );

    this.movementPage$ = combineLatest([this.pagination$, this.restrictedMovementSearch$, this.refreshTrigger$]).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(([pagination, search]) => {
        return this.movementService.findMovements$({...search}, pagination, this.#injector);
      }),
      tap(() => this.loading$.next(false)),
      shareReplay()
    );

    const movementSearchSignal = toSignal(this.movementSearch$);
    this.expeditionMovementTypeSelected = computed(() => movementSearchSignal()?.movementTypes?.some(movementType => movementType.movementKind === 'EXPEDITION'));
  }

  ngOnInit() {
    this.movementSearch$.next(this.movementSearch || {});
  }

  ngOnChanges(changes: ComponentChanges<MovementListComponent>) {
    if (changes.movementSearch && this.restrictedMovementSearch$) {
      this.movementSearch$.next(changes.movementSearch.currentValue);
    }
  }

  identity(movement: any): Movement {
    return movement as Movement;
  }

  loadMovementsList(event: TableLazyLoadEvent) {
    this.loading$.next(true);
    const pagination = this.getPagination(this.paginationService.getTablePagination(event, this.rowsPerPage));
    this.pagination$.next(pagination)
  }

  handleMinDateSelected(pickedDate: FoodbankDatePickerEvent) {
    if (pickedDate.value == null) {
      this.dateSearch = {
        ...this.dateSearch,
        minDate: undefined
      }
    } else {
      this.minDate = pickedDate.value as Date;

      this.dateSearch = {
        ...this.dateSearch,
        minDate: this.minDate
      }
    }

    this.movementSearch = {...this.movementSearch, dateSearch: this.dateSearch};
    this.movementSearch$.next(this.movementSearch);
  }

  handleMaxDateSelected(pickedDate: FoodbankDatePickerEvent) {
    if (pickedDate.value == null) {
      this.dateSearch = {
        ...this.dateSearch,
        maxDate: undefined
      }
    } else {
      const date = new Date(pickedDate.value as Date);
      date.setDate(date.getDate() + 1);

      this.dateSearch = {
        ...this.dateSearch,
        maxDate: date
      }
    }

    this.movementSearch = {...this.movementSearch, dateSearch: this.dateSearch};
    this.movementSearch$.next(this.movementSearch);
  }

  handleArticleSelected(article?: Article) {
    this.movementSearch = {...this.movementSearch, article: article};
    this.movementSearch$.next(this.movementSearch);
  }

  handleArticleCategorySelected(articleCategory: ArticleCategory) {
    this.movementSearch = {...this.movementSearch, articleCategory: articleCategory};
    this.movementSearch$.next(this.movementSearch);
  }

  handleMovementTypeSelected(movementTypes: MovementType[]) {
    this.movementSearch = {...this.movementSearch, movementTypes: movementTypes};
    this.movementSearch$.next(this.movementSearch);
  }

  handlePalletSelected(pallet: StockPallet) {
    this.movementSearch = {...this.movementSearch, pallet: pallet};
    this.movementSearch$.next(this.movementSearch);
  }

  handleWarehouseSelected(warehouse?: Warehouse) {
    this.movementSearch = {...this.movementSearch, warehouse: warehouse};
    this.movementSearch$.next(this.movementSearch);
  }

  handleSupplierSelected(supplier?: Supplier) {
    this.movementSearch = {...this.movementSearch, supplier: supplier};
    this.movementSearch$.next(this.movementSearch);
  }

  handleOrganizationSelected(organization: Organization) {
    this.movementSearch = {...this.movementSearch, organization: organization};
    this.movementSearch$.next(this.movementSearch);
  }

  handleSupplierBatchNameContainsChanged() {
    this.movementSearch = {...this.movementSearch, supplierBatchNameContains: this.supplierBatchNameContains};
    this.movementSearch$.next(this.movementSearch);
  }

  handleInternalBatchNameContainsChanged() {
    this.movementSearch = {...this.movementSearch, internalBatchNameContains: this.internalBatchNameContains};
    this.movementSearch$.next(this.movementSearch);
  }

  handleDeliveryTicketContainsChanged() {
    this.movementSearch = {...this.movementSearch, deliveryTicketContains: this.deliveryTicketContains};
    this.movementSearch$.next(this.movementSearch);
  }

  openNewMovementDialog() {
    this.ref = this.dialogService.open(MovementDialogComponent, {
      header: 'Create movement',
      width: '40%'
    });

    this.ref.onClose.subscribe(_ => this.refresh());
  }

  private getPagination(pagination: Pagination) {
    return this.printMode ? {page: 0, size: 1000} : pagination;
  }

  private refresh() {
    this.refreshTrigger$.next();
  }
}
