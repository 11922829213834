import {Component, computed, effect, inject, Injector, OnInit, signal, Signal, untracked, WritableSignal} from "@angular/core";
import {FileUploadModule} from "primeng/fileupload";
import {NgIf} from "@angular/common";
import {TableModule} from "primeng/table";
import {ArticleService} from "../../services/article.service";
import {TooltipModule} from "primeng/tooltip";
import {rxResource, toObservable} from "@angular/core/rxjs-interop";
import {debounceTime, filter, of, Subject, take} from "rxjs";
import {CheckboxModule} from "primeng/checkbox";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputNumberModule} from "primeng/inputnumber";
import {UserService} from "../../services/user.service";
import {User} from "@model/user";
import {ButtonModule} from "primeng/button";
import {BulkFoodImportService, ImportRow} from "../../services/bulk-food-import.service";
import {Article} from "@model/article";
import {NumberComponent} from "../../components/number/number.component";

// Constants
const STORAGE_KEY = 'FOODBANK_BULK_IMPORT_STATE';
const DEBOUNCE_SAVE_MS = 500;
const DEBOUNCE_FOOD_ID_MS = 300;

interface BulkFoodImportPreferences {
  data: Omit<ImportRow, 'articleRef'>[];
  showOnlyProblems: boolean;
}

@Component({
  selector: 'foodbank-bulk-food-import',
  templateUrl: './bulk-food-import.component.html',
  styleUrl: './bulk-food-import.component.scss',
  imports: [
    FileUploadModule,
    NgIf,
    TableModule,
    TooltipModule,
    CheckboxModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    NumberComponent
  ]
})
export class BulkFoodImportComponent implements OnInit {
  // Injected Services
  readonly #articleService = inject(ArticleService);
  readonly #userService = inject(UserService);
  readonly #bulkFoodImportService = inject(BulkFoodImportService);
  readonly #injector = inject(Injector);

  // State
  readonly data: WritableSignal<ImportRow[]> = signal([]);
  readonly currentUser: Signal<User | undefined>;
  readonly showOnlyProblems = signal(false);

  // Subjects for debounced operations
  readonly #saveStateSubject = new Subject<void>();
  readonly #foodIdChangeSubject = new Subject<{ row: ImportRow, newId: string }>();

  // Computed Properties
  readonly hasData = computed(() => this.data().length > 0);
  readonly problemRows = computed(() => {
    return this.data().filter(row => {
      const isValid = this.isValid(row)();
      return isValid === false;
    });
  });
  readonly statusMessage = computed(() => {
    const problemCount = this.problemRows().length;
    return problemCount === 0
      ? 'All rows are valid'
      : `There are ${problemCount} rows with problems`;
  });
  readonly filteredData = computed(() => {
    const rows = this.data();
    if (!this.showOnlyProblems()) {
      return rows;
    }
    return rows.filter(row => row.articleRef.isLoading() || !untracked(this.isValid(row)));
  });

  constructor() {
    this.currentUser = this.#userService.getCurrentUser();
    this.#initializeStateFromPreferences();
    this.#setupDebouncing();
    this.#setupEffects();
  }

  ngOnInit() {
  }

  // Public Methods
  onUpload(event: { files: File[] }): void {
    const uploadedFile = event.files[0];
    this.#bulkFoodImportService.importExcelFile(uploadedFile, this.#injector)
      .then(importRows => {
        this.data.set(importRows);
        this.#saveState();
      });
  }

  onFoodIdChange(row: ImportRow, newId: string): void {
    row.productId = newId;
    this.#foodIdChangeSubject.next({row, newId});
  }

  onQuantityChange(row: ImportRow, newQuantity: number): void {
    row.quantity = newQuantity;
    this.#debouncedSave();
  }

  clearData(): void {
    this.data.set([]);
    const user = this.currentUser();
    if (user) {
      this.#userService.setUserPreferences(user, STORAGE_KEY, null);
    }
  }

  isValid = (row: ImportRow) => computed(() => {
    const ref = row.articleRef;
    return ref.isLoading() ? undefined : ref.value() !== undefined;
  });

  // Private Methods
  #initializeStateFromPreferences(): void {
    toObservable(this.#userService.getCurrentUserPreferences<BulkFoodImportPreferences | undefined>(STORAGE_KEY, undefined))
      .pipe(
        filter(preferences => !!preferences),
        take(1)
      )
      .subscribe(preferences => {
        this.#restoreState(this.currentUser(), preferences);
      });
  }

  #setupDebouncing(): void {
    this.#saveStateSubject.pipe(
      debounceTime(DEBOUNCE_SAVE_MS)
    ).subscribe(() => {
      this.#saveState();
    });

    this.#foodIdChangeSubject.pipe(
      debounceTime(DEBOUNCE_FOOD_ID_MS)
    ).subscribe(({row, newId}) => {
      this.#updateArticleRef(row, newId);
      this.#debouncedSave();
    });
  }

  #setupEffects(): void {
    effect(() => {
      this.showOnlyProblems();
      this.#debouncedSave();
    });
  }

  #updateArticleRef(row: ImportRow, newId: string): void {
    this.#articleService.getArticle$(newId)
      .pipe(take(1))
      .subscribe(article => row.articleRef.value.set(article));
  }

  #debouncedSave(): void {
    this.#saveStateSubject.next();
  }

  #saveState(): void {
    const user = this.currentUser();
    if (!user) {
      return;
    }

    const state: BulkFoodImportPreferences = {
      data: this.data().map(({articleRef, ...rest}) => rest),
      showOnlyProblems: this.showOnlyProblems()
    };

    this.#userService.setUserPreferences(user, STORAGE_KEY, state);
  }

  #restoreState(currentUser: User | undefined, preferences: BulkFoodImportPreferences | null): void {
    if (!preferences?.data?.length) {
      return;
    }

    try {
      const restoredData: ImportRow[] = preferences.data.map((item) => ({
        ...item,
        articleRef: rxResource<Article | undefined, Error>({
          loader: () => item.productId ? this.#articleService.getArticle$(item.productId) : of(undefined),
          injector: this.#injector
        })
      }));
      this.data.set(restoredData);

      if (typeof preferences.showOnlyProblems === 'boolean') {
        this.showOnlyProblems.set(preferences.showOnlyProblems);
      }
    } catch (e) {
      console.error('Failed to restore state:', e);
      if (currentUser) {
        this.#userService.setUserPreferences(currentUser, STORAGE_KEY, null);
      }
    }
  }
}
