import {Component, input, model, Signal} from '@angular/core';
import {NgIf} from "@angular/common";
import {LanguageDirective} from "@directives/language.directive";
import {Role} from "@model/role";
import {UnsupportedLanguageDirective} from "@directives/language.unsupported.directive";

@Component({
  selector: 'foodbank-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
  imports: [
    NgIf,
    LanguageDirective,
    UnsupportedLanguageDirective
  ]
})
export class RoleComponent {

  ngModel = model<Role>();
  showId = input(false);

}
