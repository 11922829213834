<p-table *ngIf="!printMode"
         [loading]="(loading$ | async) || false"
         loadingIcon="pi pi-spin pi-spinner big-loading-icon"
         [value]="(movementPage$ | async)?.content || []"
         class="p-datatable-gridlines p-datatable-sm"
         [totalRecords]="(movementPage$ | async)?.totalElements || 0"
         [showCurrentPageReport]="true"
         [paginator]="true"
         [rows]="rowsPerPage"
         [rowsPerPageOptions]="[10, 25, 50, 100]"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} movements"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         sortMode="multiple"
         [autoLayout]="true"
         dataKey="id"
         [multiSortMeta]="[{ field: 'DATE', order: -1 }]"
         [lazy]="true"
         (onLazyLoad)="loadMovementsList($event)">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <span class="flex">
        <h2 class="m-0">Movements</h2>
        <!--        <p-button *ngIf="filter" class="ml-2" icon="pi pi-plus" label="Add item to stock" (onClick)="openNewMovementDialog()" [rounded]="true" />-->
        <!--        Maybe we add it back as New Movement -->
        <p-multiSelect *ngIf="showColumnConfig()"
          class="ml-2"
          [options]="allColumnsWithDefaultConfig()"
          [(ngModel)]="selectedColumns"
          optionLabel="label"
          dataKey="field"
          selectedItemsLabel="{{allColumnsWithDefaultConfig().length === selectedColumns().length ? 'All' : selectedColumns().length + '/' + allColumnsWithDefaultConfig().length}} columns selected"
          [style]="{'min-width': '200px'}"
          placeholder="Choose Columns" />
      </span>

      <span class="flex">
        <p-columnFilter *ngIf="filter" field="categories" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-category>
            Category
            <foodbank-article-category-single-selection
              [selectedArticleCategory]="category"
              (onArticleCategorySelected)="handleArticleCategorySelected($event)"/>
          </ng-template>
        </p-columnFilter>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr class="movement-list-row">
      <th pSortableColumn="PALLET_ID" [pSortableColumnDisabled]="!filter" *ngIf="columnsConfig().stockPallet$?.visible">
        <span i18n>Pallet</span>
        <p-sortIcon *ngIf="filter" field="PALLET_ID"/>
      </th>
      <th pSortableColumn="ARTICLE_ID" *ngIf="filter && columnsConfig().article$?.visible">
        <span i18n>Article</span>
        <p-sortIcon *ngIf="filter" field="ARTICLE_ID"/>
      </th>
      <th pSortableColumn="DATE" *ngIf="columnsConfig().date?.visible">
        <span i18n>Date</span>
        <p-sortIcon *ngIf="filter" field="DATE"/>
      </th>
      <th *ngIf="columnsConfig().movementType$?.visible">
        <span i18n>Type</span>
      </th>
      <th *ngIf="columnsConfig().quantity?.visible">
        <span i18n>Quantity</span>
      </th>
      <th *ngIf="filter && columnsConfig().warehouse$?.visible">
        <span i18n>Warehouse</span>
      </th>
      <th *ngIf="filter && columnsConfig().supplier$?.visible">
        <span i18n>Supplier</span>
      </th>
      <th *ngIf="filter && columnsConfig().supplierBatchName?.visible">
        <span i18n>Supplier Batch</span>
      </th>
      <th *ngIf="filter && columnsConfig().internalBatchName?.visible">
        <span i18n>Internal Batch</span>
      </th>
      <th *ngIf="columnsConfig().deliveryTicket?.visible">
        <span i18n>Delivery ticket</span>
      </th>
      <th *ngIf="columnsConfig().user$?.visible">
        <span i18n>User</span>
      </th>
      <th *ngIf="columnsConfig().organization$?.visible || expeditionMovementTypeSelected()">
        <span i18n>Organization</span>
      </th>
    </tr>
    <tr *ngIf="filter && columnsConfig().stockPallet$?.visible">
      <th>
        <p-columnFilter field="pallets" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-pallet>
            <ng-container *ngIf="(palletSearch$ | async) as palletSearch">
              <foodbank-pallet-single-selection
                ngDefaultControl
                [palletSearch]="palletSearch"
                [selectedPallet]="pallet"
                (onPalletSelected)="handlePalletSelected($event)"/>
            </ng-container>
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="columnsConfig().article$?.visible">
        <p-columnFilter field="article" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-article>
            <foodbank-article-selection
              ngDefaultControl
              [ngModel]="article" (ngModelChange)="handleArticleSelected($event)"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="columnsConfig().date?.visible">
        <p-columnFilter field="minDate" [showMenu]="false" style="width: 10em; display: inline-block">
          <ng-template pTemplate="filter">
            <foodbank-date-picker
              ngDefaultControl
              [ngModel]="minDate"
              (onSelect)="handleMinDateSelected($event)"
              [showSelectionOptions]="false"
              [required]="false"
              [placeholder]="'min'"
              [showButtonBar]="true"/>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter field="maxDate" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-date-picker
              ngDefaultControl
              [ngModel]="maxDate"
              (onSelect)="handleMaxDateSelected($event)"
              [showSelectionOptions]="false"
              [required]="false"
              [placeholder]="'max'"
              [showButtonBar]="true"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="columnsConfig().movementType$?.visible">
        <p-columnFilter field="type" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-movementTypes>
            <foodbank-movementType-multi-selection
              ngDefaultControl
              [ngModel]="movementTypes"
              (onMovementTypeSelected)="handleMovementTypeSelected($event)"
            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="columnsConfig().quantity?.visible"></th>
      <th *ngIf="filter && columnsConfig().warehouse$?.visible">
        <p-columnFilter field="warehouse" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-warehouse>
            <ng-container *ngIf="(warehouseSearch$ | async) as warehouseSearch">
              <foodbank-warehouse-selection ngDefaultControl
                                            [ngModel]="warehouse"
                                            [warehouseSearch]="warehouseSearch"
                                            (ngModelChange)="handleWarehouseSelected($event)"/>
            </ng-container>
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="filter && columnsConfig().supplier$?.visible">
        <p-columnFilter field="supplier" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-supplier>
            <foodbank-supplier-selection
              ngDefaultControl
              [ngModel]="supplier"
              [supplierSearch]="{}"
              (ngModelChange)="handleSupplierSelected($event)"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="columnsConfig().supplierBatchName?.visible">
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="supplierBatchNameContains" (keyup.enter)="handleSupplierBatchNameContainsChanged()"/>
      </th>
      <th *ngIf="columnsConfig().internalBatchName?.visible">
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="internalBatchNameContains" (keyup.enter)="handleInternalBatchNameContainsChanged()"/>
      </th>
      <th *ngIf="columnsConfig().deliveryTicket?.visible">
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="deliveryTicketContains" (keyup.enter)="handleDeliveryTicketContainsChanged()"/>
      </th>
      <th *ngIf="columnsConfig().user$?.visible"></th>
      <th *ngIf="filter && columnsConfig().organization$?.visible || expeditionMovementTypeSelected()">
        <p-columnFilter field="organizations" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-organization>
            <ng-container *ngIf="(organizationSearch$ | async) as organizationSearch">
              <foodbank-organization-selection ngDefaultControl
                                               [ngModel]="organization"
                                               (ngModelChange)="handleOrganizationSelected($event)"
                                               [organizationSearch]="organizationSearch"
              />
            </ng-container>
          </ng-template>
        </p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-movement>
    <ng-container *ngIf="identity(movement) as movement">
      <tr>
        <td *ngIf="columnsConfig().stockPallet$?.visible">
          <ng-container *ngIf="(movement.stockPallet$ | async) as stockPallet">
            <foodbank-pallet ngDefaultControl [pallet]="stockPallet" [showIdOnly]="printMode"/>
          </ng-container>
        </td>
        <td *ngIf="filter && columnsConfig().article$?.visible">
          <ng-container *ngIf="(movement.article$ | async) as article">
            <foodbank-article ngDefaultControl [ngModel]="article"/>
          </ng-container>
        </td>
        <td *ngIf="columnsConfig().date?.visible">
          {{ movement.timestamp | date: 'dd/MM/yyyy' }}
        </td>
        <td *ngIf="columnsConfig().movementType$?.visible">
          <foodbank-movement-type ngDefaultControl [ngModel]="(movement.movementType$ | async) || undefined"/>
        </td>
        <td *ngIf="columnsConfig().quantity?.visible">
          {{ movement.quantity | number: '1.2-2' }}
        </td>
        <td *ngIf="filter && columnsConfig().warehouse$?.visible">
          <foodbank-warehouse ngDefaultControl [ngModel]="(movement.warehouse$ | async) || undefined" [showId]="false"/>
        </td>
        <td *ngIf="filter && columnsConfig().supplier$?.visible">
          <foodbank-supplier ngDefaultControl [ngModel]="(movement.supplier$ | async) || undefined" [showId]="false"/>
        </td>
        <td *ngIf="filter && columnsConfig().supplierBatchName?.visible">
          {{ movement.supplierBatchName }}
        </td>
        <td *ngIf="filter && columnsConfig().internalBatchName?.visible">
          {{ movement.internalBatchName }}
        </td>
        <td *ngIf="columnsConfig().deliveryTicket?.visible">
          {{ movement.deliveryTicket }}
        </td>
        <td *ngIf="columnsConfig().user$?.visible">
          <foodbank-user ngDefaultControl [ngModel]="(movement.user$ | async) || undefined"/>
        </td>
        <td *ngIf="columnsConfig().organization$?.visible || expeditionMovementTypeSelected()">
          <foodbank-organization [ngModel]="(movement.organization$ | async) || undefined" [showId]="true" ngDefaultControl></foodbank-organization>
        </td>
      </tr>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6"><span i18n>No movements found.</span></td>
    </tr>
  </ng-template>
</p-table>

<table *ngIf="printMode" class="movement-list-printable text-base">
  <thead>
  <tr class="movement-list-row">
    <th i18n>Pallet</th>
    <th i18n>Date</th>
    <th i18n>Type</th>
    <th i18n>Quantity</th>
    <th i18n>User</th>
    <th i18n>Delivery ticket</th>
  </tr>
  </thead>
  <tbody class="text-sm">
  <tr *ngFor="let movement of (movementPage$ | async)?.content || []">
    <td>
      <foodbank-pallet ngDefaultControl [pallet]="(movement.stockPallet$ | async) || undefined" [showIdOnly]="true"/>
    </td>
    <td>{{ movement.timestamp | date: 'dd/MM/yyyy' }}</td>
    <td>{{ (movement.movementType$ | async)?.movementKind }}</td>
    <td>{{ movement.quantity | number: '1.2-2' }}</td>
    <td><span *ngIf="movement.user$ | async as user">{{ user.name }}</span></td>
    <td>{{ movement.deliveryTicket }}</td>
  </tr>
  </tbody>
</table>
