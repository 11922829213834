import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { forkJoin, map, Observable, shareReplay, switchMap, take } from 'rxjs';
import { environment } from '@environments/environment';
import { Preparation } from '@model/preparation';
import { ExpeditionDispatchRequestDto, PreparationDto } from '@typedefs/stock-rest';
import { UserService } from '@services/user.service';
import { PreparationService } from "@services/preparation.service";
import { PreparationItem } from "@model/preparation-item";

@Injectable({
  providedIn: 'root'
})
export class ExpeditionService {

  constructor(private httpClient: HttpClient,
              private preparationService: PreparationService,
              private userService: UserService) {
  }

  public autofillExpedition$(preparation: Preparation): Observable<Preparation> {
    return this.httpClient.post<PreparationDto>(`${environment.apiUrl}/preparations/${preparation.id}/expeditions/autofill`, {})
      .pipe(
        map(preparationDto => this.preparationService.mapToPreparation(preparationDto)),
        take(1),
        shareReplay()
      );
  }

  public dispatchExpedition$(preparation: Preparation, comment: string, selectedItems?: PreparationItem[]): Observable<Preparation> {
    if (!selectedItems?.length) {
      return this.dispatchWithOrganizations(preparation, { comment, organizationIds: []});
    }

    return forkJoin(
      selectedItems.map(item => item.organization$)
    ).pipe(
      map(organizations => organizations.map(org => org?.id).filter((id): id is number => id != null)),
      switchMap(organizationIds => this.dispatchWithOrganizations(preparation, { comment, organizationIds })),
      take(1),
      shareReplay()
    );
  }

  private dispatchWithOrganizations(preparation: Preparation, expeditionDispatchRequest: ExpeditionDispatchRequestDto): Observable<Preparation> {
    return this.httpClient.post<PreparationDto>(
      `${environment.apiUrl}/preparations/${preparation.id}/expeditions`,
      expeditionDispatchRequest
    ).pipe(
      map(preparationDto => this.preparationService.mapToPreparation(preparationDto)),
      take(1),
      shareReplay()
    );
  }
}
