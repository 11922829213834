import {Component, inject, Injector, model} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Member} from '@model/member';
import {MemberService} from '@services/member.service';
import {NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {RoleSelectionComponent} from "@components/role/selection/role-selection.component";
import {Role} from '@model/role';
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";

@Component({
  selector: 'foodbank-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule, RoleSelectionComponent, InputSwitchModule, Button, OrganizationSingleSelectionComponent]
})
export class MemberEditComponent {

  member = model<Member>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Member>);
  #memberService = inject(MemberService);
  #injector = inject(Injector);

  constructor() {
    this.member.set(this.config.data);
  }

  save(member: Member) {
    this.#memberService.updateMember(member)
      .subscribe(member => {
        this.member.set(member);
        this.ref.close(member)
      });
  }

  close() {
    this.ref.close({});
  }

  saveRole(role: Role) {
    if (this.member()) {
      this.#memberService.updateMember(this.member()!)
        .subscribe(member => {
          this.member.set(member);
        });
    }
  }

}
