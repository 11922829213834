import {Component, computed, inject, input, model, Signal, signal, WritableSignal} from '@angular/core';
import {RoleSearch} from '@model/search/role-search';
import {Role} from '@model/role';
import {RoleService} from '@services/role.service';
import {PaginationService} from "@services/pagination.service";
import {Pagination} from "@services/pagination";
import {loadSignalFromObservable} from "@util/foodbanks-signal-rxjs-interop";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {AutoFocus} from "primeng/autofocus";
import {RoleComponent} from "@components/role/role.component";

@Component({
  selector: 'foodbank-role-selection',
  exportAs: 'roleSelection',
  templateUrl: './role-selection.component.html',
  styleUrls: ['./role-selection.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    InputTextModule,
    AutoFocus,
    RoleComponent
  ]
})
export class RoleSelectionComponent {

  ngModel = model<Role>();
  required = input(false);
  roleSearch = input<RoleSearch>({});

  nameFilter = signal('');

  roles: Signal<Role[] | undefined>;
  pagination: WritableSignal<Pagination>;
  loading = signal(true);

  #roleService = inject(RoleService);
  #paginationService = inject(PaginationService);

  constructor() {
    this.pagination = this.#paginationService.getDefaultComponentPaginationSignal();

    const actualRoleSearch: Signal<RoleSearch> = computed(() => ({
      ...this.roleSearch(),
      nameContains: this.nameFilter(),
    }));

    const rolePage = loadSignalFromObservable(() => this.#roleService.findRoles$(actualRoleSearch(), this.pagination()), this.loading);

    this.roles = computed(() => rolePage()?.content);
  }

  clear() {
    this.ngModel.set(undefined);
  }

}

