import { Component, inject, Injector, OnInit } from '@angular/core';
import {Warehouse} from '@model/warehouse';
import {BehaviorSubject, combineLatest, EMPTY, filter, forkJoin, map, mergeMap, Observable, of, shareReplay, take} from 'rxjs';
import {Preparation} from '@model/preparation';
import {PreparationService} from '@services/preparation.service';
import {UserService} from '@services/user.service';
import {ZoneService} from '@services/zone.service';
import {activeWarehouseSearchByCompany, WarehouseSearch} from '@model/search/warehouse-search';
import {StockSearch, stockSearchByWarehouse} from '@model/search/stock-search';
import {ZoneSearch, zoneSearchByCompanyAndWarehouseAvailable} from '@model/search/zone-search';
import {Zone} from "@model/zone";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FoodbankDatePickerEvent} from "@components/date/date-picker/date-picker.component";
import { MessageService, PrimeTemplate } from "primeng/api";
import {PreparationDistributionLine} from "@model/preparation-distribution-line";
import {PackageDistributionOption} from "@typedefs/stock-rest";
import { CheckboxChangeEvent, CheckboxModule } from "primeng/checkbox";
import {PreparationStockSelection} from "@model/preparation-stock-selection";
import {PreparationSelectionService} from "@services/preparation-selection.service";
import {Stock} from "@model/stock";
import { NgIf, AsyncPipe } from '@angular/common';
import { PanelModule } from 'primeng/panel';
import { WarehouseComponent } from '../../warehouse/warehouse.component';
import { FormsModule } from '@angular/forms';
import { WarehouseSingleSelectionComponent } from '../../warehouse/selection/single/warehouse-single-selection.component';
import { PreparationZoneSingleSelectionComponent } from '../../preparation-zone/selection/single/preparation-zone-single-selection.component';
import { DatePickerComponent } from '../../date/date-picker/date-picker.component';
import { TableSizeComponent } from '../../table-size/table-size.component';
import { FieldsetModule } from 'primeng/fieldset';
import { DropdownModule } from 'primeng/dropdown';
import { Button } from 'primeng/button';
import { TabViewModule } from 'primeng/tabview';
import { DragDropModule } from 'primeng/dragdrop';
import { StockPreparationTableComponent } from '../table/stock-preparation-table/stock-preparation-table.component';
import { SetupPreparationTableComponent } from '../table/setup-preparation-table/setup-preparation-table.component';
import { PreparationDistributionComponent } from '../distribution/preparation-distribution.component';

type PackageDistributionOptionLabels = { label: string, value: PackageDistributionOption; companyDefault?: boolean };

@Component({
    selector: 'foodbank-preparation-new',
    templateUrl: './preparation-new.component.html',
    styleUrl: './preparation-new.component.scss',
    imports: [NgIf, PanelModule, PrimeTemplate, WarehouseComponent, FormsModule, WarehouseSingleSelectionComponent, PreparationZoneSingleSelectionComponent, DatePickerComponent, TableSizeComponent, FieldsetModule, CheckboxModule, DropdownModule, Button, TabViewModule, DragDropModule, StockPreparationTableComponent, SetupPreparationTableComponent, PreparationDistributionComponent, AsyncPipe]
})
export class PreparationNewComponent implements OnInit {

  tableSizeStyleClass = localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '';

  preparation: Partial<Preparation> | undefined;

  selectedDate?: Date;
  selectedWarehouse?: Warehouse;
  selectedPreparationZone?: Zone;
  packageDistributionOptions$: Observable<PackageDistributionOptionLabels[]>;

  selectedDate$: BehaviorSubject<Date | undefined>;
  selectedWarehouse$: BehaviorSubject<Warehouse | undefined>;
  selectedPreparationZone$: BehaviorSubject<Zone | undefined>;

  createdPreparation$: BehaviorSubject<Preparation | undefined>;

  filterWarehouseSearch$: Observable<WarehouseSearch>;
  filterZoneSearch$: Observable<ZoneSearch>;
  stockFilter$: Observable<StockSearch | undefined>;
  private refreshTrigger$ = new BehaviorSubject<void>(undefined);

  preparationSelectionsToCreate: Stock[] = [];
  preparationSelectionsToRemove: PreparationStockSelection[] = [];
  activeTabIndex: number = 0;

  #injector = inject(Injector)

  constructor(
    private preparationService: PreparationService,
    private preparationSelectionService: PreparationSelectionService,
    private userService: UserService,
    private zoneService: ZoneService,
    private messageService: MessageService,
    public modalDialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig<Partial<Preparation>>
  ) {
    this.selectedWarehouse$ = new BehaviorSubject<Warehouse | undefined>(undefined);
    this.selectedPreparationZone$ = new BehaviorSubject<Zone | undefined>(undefined);
    this.selectedDate$ = new BehaviorSubject<Date | undefined>(undefined);
    this.createdPreparation$ = new BehaviorSubject<Preparation | undefined>(undefined);

    this.createdPreparation$.pipe(
      filter(preparation => !!preparation),
      mergeMap(preparation => preparation!.warehouse$),
      take(1)).subscribe(warehouse => this.selectWarehouse(warehouse, false));

    this.createdPreparation$.pipe(filter(preparation => !!preparation),
      mergeMap(preparation => preparation!.zone$),
      take(1)).subscribe(zone => this.selectPreparationZone(zone, false));

    this.createdPreparation$.pipe(filter(preparation => !!preparation),
      map(preparation => preparation!.date),
      take(1)).subscribe(selectedDate => {
      this.selectedDate = selectedDate;
      this.selectedDate$.next(selectedDate);
    });

    const userCompany$ = this.userService.getCurrentUserCompany$();
    userCompany$.subscribe(company => {
      this.preparation = this.config.data || {
        proposePalletWeights: true,
        proposeDistributionByAssociation: true,
        handleMainWarehouses: false,
        packageDistributionOption: company.allocationByPackage ? 'PARCEL' : 'UNIT'
      };

      if (this.preparation.id) {
        this.createdPreparation$.next(this.preparation as Preparation);
      }
    })
    this.packageDistributionOptions$ = userCompany$.pipe(
      map(company => company.allocationByPackage
        ? [{value: 'UNIT', label: 'unit'}, {value: 'PARCEL', label: 'parcel (company default)', companyDefault: true}]
        : [{value: 'UNIT', label: 'unit (company default)', companyDefault: true}, {value: 'PARCEL', label: 'parcel'}])
    );

    this.filterWarehouseSearch$ = userCompany$.pipe(map(currentUserCompany => activeWarehouseSearchByCompany(currentUserCompany)));
    this.filterZoneSearch$ = combineLatest([userCompany$, this.selectedWarehouse$.pipe(filter(warehouse => !!warehouse))])
      .pipe(
        map(([currentUserCompany, selectedWarehouse]) => zoneSearchByCompanyAndWarehouseAvailable(currentUserCompany, selectedWarehouse!))
      );

    this.stockFilter$ = combineLatest([this.selectedWarehouse$, this.refreshTrigger$]).pipe(
      filter(([warehouse, _]) => !!warehouse),
      map(([warehouse]) => ({
        ...stockSearchByWarehouse(warehouse),
        excludePreparationZones: true
      })),
      shareReplay()
    );
  }

  ngOnInit(): void {
    this.createdPreparation$ = new BehaviorSubject<Preparation | undefined>(this.preparation as Preparation);
    this.activeTabIndex = this.preparation?.id ? 1 : 0;
  }

  selectWarehouse(warehouse?: Warehouse, save = true) {
    if (warehouse?.id === this.selectedWarehouse?.id) {
      return;
    }

    this.selectedWarehouse = warehouse;
    this.preparation!.warehouse$ = warehouse ? of(warehouse) : EMPTY;
    this.preparation!.company$ = warehouse ? warehouse.company$ : EMPTY; // FIXME: company$ should be set in the preparationService

    this.selectedWarehouse$.next(warehouse);
    if (save) {
      this.savePreparation(this.preparation)
        .subscribe({
          next: preparation => {
            this.preparation = preparation;
            this.createdPreparation$.next(preparation);
          }
        });
    }
  }

  selectPreparationZone(zone: Zone | undefined, save = true) {
    if (zone?.location === this.selectedPreparationZone?.location) {
      return;
    }

    if (zone) {
      this.selectedPreparationZone = zone;
      this.preparation!.zone$ = of(zone);
      this.preparation!.location = zone.location;
      this.selectedPreparationZone$.next(zone);

      if (save) {
        this.savePreparation(this.preparation)
          .subscribe({
            next: preparation => {
              this.preparation = preparation;
              this.createdPreparation$.next(preparation);
            }
          });
      }
    } else {
      this.filterZoneSearch$.pipe(
        take(1),
        mergeMap(zoneSearch => this.zoneService.findZones$(zoneSearch))
      ).subscribe(availableZones => {
        if (availableZones.length === 0) {
          this.messageService.add({severity: 'warn', summary: 'Cannot change preparation zone', detail: 'There arent any other available preparation zones'});
        } else {
          this.selectedPreparationZone = zone;
          delete this.preparation!.zone$;
          this.preparation!.location = undefined;
        }
      })
    }
  }

  selectDate(event: FoodbankDatePickerEvent) {
    const selectedDate = event.utcValue as Date;
    if (selectedDate === this.selectedDate) {
      return;
    }

    this.selectedDate = selectedDate;
    this.preparation!.date = selectedDate;
    this.selectedDate$.next(selectedDate);
    this.savePreparation(this.preparation)
      .subscribe({
        next: preparation => {
          this.preparation = preparation;
          this.createdPreparation$.next(preparation);
        }
      });
  }

  private savePreparation(preparation: Partial<Preparation> | undefined): Observable<Preparation> {
    if (preparation === undefined) {
      return EMPTY;
    }

    const allMandatoryFields$: Observable<boolean> = combineLatest([
      this.preparation?.company$ ?? of(undefined),
      this.preparation?.warehouse$ ?? of(undefined),
      this.preparation?.zone$ ?? of(undefined)
    ]).pipe(
      map(([company, warehouse, zone]) => !(!company || !warehouse || !zone || !preparation?.date))
    )

    const savedPreparation$ = allMandatoryFields$.pipe(
      filter(valid => valid),
      mergeMap(_ => this.preparationService.saveNonFeadPreparation$(this.preparation as Preparation)),
      shareReplay()
    );

    savedPreparation$.subscribe(preparation => {
      if (this.config.header === 'New preparation') {
        this.config.header = 'Edit preparation ' + preparation.id
        this.config.height = '80%'
      }
    })
    return savedPreparation$;
  }

  updatePreparationSelectionToCreate(stock: Stock[]) {
    this.preparationSelectionsToCreate = stock;
  }

  updatePreparationSelectionToRemove(preparationSelection: PreparationStockSelection[]) {
    this.preparationSelectionsToRemove = preparationSelection;
  }

  removeSelection() {
    const movedPreparationSelections$ = this.preparationSelectionsToRemove
      .map(preparationSelection => this.preparationSelectionService.deletePreparationSelection$(preparationSelection))

    forkJoin(movedPreparationSelections$).subscribe(() => {
      this.updatePreparationSelectionToRemove([]);
      this.refreshTrigger$.next();
    });
    this.updatePreparationSelectionToRemove([]);
  }

  addSelection() {
    const preparationSelectionsToCreate: PreparationStockSelection[] = this.preparationSelectionsToCreate
      .map(stock => {

        const dispatchWeight = stock.quantity || 0;
        let dispatchUnitCount: number;
        let dispatchParcelCount: number;

        if (stock.unitWeight <= 0) {
          dispatchUnitCount = 0;
          dispatchParcelCount = 0;
        } else {
          dispatchUnitCount = dispatchWeight * 1000 / stock.unitWeight;
          dispatchParcelCount = dispatchUnitCount / stock.unitsPerParcel;
        }

        return ({
          preparation$: of(this.preparation),
          stock$: of(stock),
          dispatchWeight,
          dispatchUnitCount,
          dispatchParcelCount,
        } as PreparationStockSelection);
      });
    const movedPreparationSelections$ = this.preparationSelectionService.savePreparationSelections$(this.preparation as Preparation, preparationSelectionsToCreate, this.#injector)

    movedPreparationSelections$.subscribe(() => {
      this.updatePreparationSelectionToCreate([]);
      this.refreshTrigger$.next(undefined);
    });
    this.updatePreparationSelectionToCreate([]);
  }

  updateDistributionList($event?: PreparationDistributionLine) {
    // this.distributionList$.next($event);
  }

  updateDistributionConfigSelectedParameters($event: CheckboxChangeEvent) {
    this.savePreparation(this.preparation).subscribe(preparation => {
      this.preparation = preparation;
      this.createdPreparation$.next(preparation);
    });
  }

  createPreparationLines() {
    const preparation = this.preparation as Preparation;
    this.preparationService.createPreparationLines$(preparation).subscribe({
      next: () => {
        this.messageService.add({severity: 'success', summary: 'Success', detail: `Preparation (${preparation.id}) lines created`});
        this.modalDialogRef.close(preparation)
      },
      error: () => {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Failed to create preparation lines'})
      }
    });

  }
}
