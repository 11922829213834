import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OrganizationDto, OrganizationSearchDto, SupplyWeek} from '@typedefs/stock-rest';
import {BehaviorSubject, map, mergeMap, Observable, shareReplay, take} from 'rxjs';
import {environment} from '@environments/environment';
import {Organization} from '@model/organization';
import {Page} from '@typedefs/page';
import {OrganizationSearch} from '@model/search/organization-search';
import {Pagination} from '@services/pagination';
import {PreparationItemService} from '@services/preparation-item.service';
import {WarehouseService} from "@services/warehouse.service";
import {copyCommonFields} from "@model/mapping-utils";
import {FoodbankCache} from "@services/foodabank-cache";

type organizationPagination = Pagination;

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private invalidateCache$ = new BehaviorSubject<void>(undefined);
  cacheById: { [key: number]: Observable<Organization> } = {};

  private httpClient = inject(HttpClient);
  private warehouseService = inject(WarehouseService);
  private injector = inject(Injector);

  public getOrganization$(id: number): Observable<Organization> {
    if (!this.cacheById[id]) {
      this.cacheById[id] = this.invalidateCache$.pipe(
        mergeMap(() => this.httpClient.get<OrganizationDto>(`${environment.apiUrl}/organizations/${id}`)),
        map(organization => this.loadOrganization(organization)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
    }

    return this.cacheById[id];
  }

  findOrganizations$(organizationSearch: OrganizationSearch, params?: organizationPagination): Observable<Page<Organization>> {
    const organizationSearchDto = this.mapToOrganizationSearchDto(organizationSearch);
    return this.httpClient.post<Page<OrganizationDto>>(`${environment.apiUrl}/organizations/search`, organizationSearchDto, {params})
      .pipe(
        map(page => {
          const organizations: Organization[] = page.content.map(organizationDto => this.loadOrganization(organizationDto));
          return {
            ...page,
            content: organizations
          }
        })
      );
  }

  public loadOrganization(organizationDto: OrganizationDto): Organization {
    return {
      ...organizationDto,
      warehouse$: this.warehouseService.getWarehouse$(organizationDto.warehouseId),
      isPreferredWeekForDelivery: (week: string) => {
        return this.isPreferredWeek(organizationDto.preferredSupplyWeek, week);
      }
    };
  }

  isPreferredWeek(preferredSupplyWeek: SupplyWeek, week: string): boolean {
    try {
      const [yearStr, weekOfYearStr] = week.split("/");
      const year = parseInt(yearStr);
      const weekOfYear = parseInt(weekOfYearStr);

      if (weekOfYear % 2 == 0 && preferredSupplyWeek === 'WEEKS_EVEN') {
        return true;
      }

      if (weekOfYear % 2 == 1 && preferredSupplyWeek === 'WEEKS_UNEVEN') {
        return true;
      }

      const weekOfMonth = this.getWeekOfMonth(year, weekOfYear);
      switch (weekOfMonth) {
        case 1:
          return !['WEEK_2', 'WEEK_3', 'WEEK_4', 'WEEK_24', 'WEEK_34', 'WEEK_23'].includes(preferredSupplyWeek);
        case 2:
          return !['WEEK_1', 'WEEK_3', 'WEEK_4', 'WEEK_13', 'WEEK_34', 'WEEK_14'].includes(preferredSupplyWeek);
        case 3:
          return !['WEEK_1', 'WEEK_2', 'WEEK_4', 'WEEK_24', 'WEEK_12', 'WEEK_14'].includes(preferredSupplyWeek);
        case 4:
          return !['WEEK_1', 'WEEK_2', 'WEEK_3', 'WEEK_13', 'WEEK_12', 'WEEK_23'].includes(preferredSupplyWeek);
        case 5:
          return !['WEEKS_EVEN', 'WEEKS_UNEVEN'].includes(preferredSupplyWeek);
        default:
          throw new Error(`Unexpected value: ${weekOfMonth}`);
      }
    } catch (e) {
      console.error(`Error while parsing week: ${week}`, e);
      return true;
    }
  }

  private getWeekOfMonth(year: number, weekOfYear: number): number {
    // Create a Date object for the first day of the given year
    const firstDayOfYear = new Date(year, 0, 1);
    // Adjust based on the day of the week of the first day of the year
    const daysToAdjust = (firstDayOfYear.getDay() === 6) ? -6 : 1 - firstDayOfYear.getDay();

    firstDayOfYear.setDate(firstDayOfYear.getDate() + weekOfYear * 7 + daysToAdjust);

    // Get the week number within the month
    return Math.ceil(firstDayOfYear.getDate() / 7);
  }

  public mapToOrganizationSearchDto(organizationSearch: OrganizationSearch): OrganizationSearchDto {
    const preparationItemService = this.injector.get(PreparationItemService);
    return {
      name: organizationSearch.name,
      zip: organizationSearch.zip,
      city: organizationSearch.city,
      active: organizationSearch.active,
      bigBirbCode: organizationSearch.bigBirbCode,
      birbCode: organizationSearch.birbCode,
      warehouseEnabled: organizationSearch.warehouseEnabled,
      feadEnabled: organizationSearch.feadEnabled,
      bankId: organizationSearch?.bank?.id,
      companyId: organizationSearch?.company?.id,
      warehouseId: organizationSearch?.warehouse?.id,
      pickupWarehouseId: organizationSearch?.pickupWarehouse?.id,
      preparationItemSearchDto: organizationSearch.preparationItemSearch && preparationItemService.mapToPreparationItemSearchDto(organizationSearch.preparationItemSearch)
    }
  }


  public updateOrganization(organization: Organization): Observable<Organization> {
    const organizationDto = this.mapToOrganizationDto(organization);
    return this.httpClient.put<OrganizationDto>(`${environment.apiUrl}/organizations/${organization.id}`, organizationDto)
      .pipe(map(organizationDto => this.mapToOrganization(organizationDto)));
  }
  public mapToOrganization(organizationDto: OrganizationDto): Organization {
    const commonFields: Organization | OrganizationDto = copyCommonFields(organizationDto, [ ]);
    return {
      ...commonFields,
      warehouse$: this.warehouseService.getWarehouse$(organizationDto.warehouseId),
      isPreferredWeekForDelivery: (week: string) => {
        return this.isPreferredWeek(organizationDto.preferredSupplyWeek, week);
      }

      // company: cache.companyCache.get(organizationDto.companyId),
    }
  }

  mapToOrganizationDto(organization: Organization): OrganizationDto {
  const organizationDto: OrganizationDto = copyCommonFields(organization, []) as OrganizationDto;
    return {
      ...organizationDto,
     //  companyId: organization.company.value()?.id!,
    };
  }

}
