import { Component, forwardRef, inject, Injector, Input } from '@angular/core';
import {StockPallet} from '@model/stock-pallet';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {StockService} from '@services/stock.service';
import {PalletLabelsComponent} from '@components/reception/reception-item-labels/pallet-labels.component';
import {Stock} from "@model/stock";
import {NgIf} from "@angular/common";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {MovementListComponent} from "@components/movement/movement-list/movement-list.component";

@Component({
    selector: 'foodbank-pallet',
    templateUrl: './pallet.component.html',
    styleUrls: ['./pallet.component.scss'],
    providers: [DialogService],
    imports: [
        NgIf,
        OverlayPanelModule,
        forwardRef(() => MovementListComponent),
    ]
})
export class PalletComponent {

  @Input()
  pallet?: StockPallet;

  @Input()
  showIdOnly: boolean = false;

  @Input()
  showHistory: boolean = false;

  stockService = inject(StockService);
  dialogService = inject(DialogService);

  ref: DynamicDialogRef | undefined;
  #injector = inject(Injector);

  openPrintLabelDialog() {
    if (!this.pallet) {
      return;
    }
    const stock$ = this.stockService.getStockByPallet$(this.pallet, this.#injector);
    stock$.subscribe(stock => {
      if (stock) {
        this.openLabelDialog(stock);
      }
    })

  }

  private openLabelDialog(stock: Stock) {
    this.ref = this.dialogService.open(PalletLabelsComponent, {
      showHeader: false,
      width: '49rem',
      data: [stock]
    });
  }
}
