<ng-container *ngIf="ngModel() as organization">
  <span class="address-block">
    <p class="label">
      <span class="id mr-1" *ngIf="showId()">#{{ organization.id }}</span>
      <i class="fa-solid fa-building mr-1"></i>
      <span class="code mr-1" *ngIf="showCode() && organization.code && organization.code != '0'">#({{organization.code}})</span>
      <span class="name">{{organization.name}}</span>
    </p>
    <span *ngIf="showAddress()">
      <p>{{organization.address}}</p>
      <p>{{organization.zip}} {{organization.city}}</p>
    </span>
  </span>
</ng-container>
