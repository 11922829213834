import { inject, Injectable } from "@angular/core";
import { StockSearch } from "@model/search/stock-search";
import { StockSearchDto } from "@typedefs/stock-rest";
import { InternalBatchSearch } from "@model/search/internal-batch-search";
import { SupplierBatchSearch } from "@model/search/supplier-batch-search";
import { Observable } from "rxjs";
import { Page } from "@typedefs/page";
import { environment } from "@environments/environment";
import { HttpClient } from "@angular/common/http";
import { Pagination } from "@services/pagination";
import { StockSearchMappingService } from "@services/stock-search-mapping.service";

type StockPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class StockSearchService {

  #httpClient = inject(HttpClient);
  #stockSearchMappingService = inject(StockSearchMappingService);

  mapToStockSearchDto(stockSearch: StockSearch): StockSearchDto {
    return this.#stockSearchMappingService.mapToStockSearchDto(stockSearch);
  }

  findInternalBatches(internalBatchSearch: InternalBatchSearch, pagination?: StockPagination): Observable<Page<string>> {
    const internalBatchSearchDto = this.#stockSearchMappingService.mapToInternalBatchSearchDto(internalBatchSearch);
    return this.#httpClient.post<Page<string>>(`${environment.apiUrl}/stocks/internal-batch/search`, internalBatchSearchDto, {params: pagination});
  }

  findSupplierBatches(supplierBatchSearch: SupplierBatchSearch, pagination?: StockPagination): Observable<Page<string>> {
    const supplierBatchSearchDto = this.#stockSearchMappingService.mapToSupplierBatchSearchDto(supplierBatchSearch);
    return this.#httpClient.post<Page<string>>(`${environment.apiUrl}/stocks/supplier-batch/search`, supplierBatchSearchDto, {params: pagination});
  }
}
