
import {Component, computed, inject, Signal, signal, WritableSignal} from '@angular/core'
import {Role} from '@model/role';
import {RoleService} from '@services/role.service';
import {UserService} from "@services/user.service";
import {RoleSearch} from "@model/search/role-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {derivedAsync} from "ngxtension/derived-async";

@Component({
  selector: 'foodbank-roles',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss'],
  providers: [DialogService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule, NgIf]

})

export class RolesComponent {
  rolePage: Signal<Page<Role> | undefined>;

  roleSearch: Signal<RoleSearch>;

  pagination: WritableSignal<Pagination>;
  private roleService = inject(RoleService);
  private paginationService = inject(PaginationService);
  private userService = inject(UserService);
  nameNlContains = signal('');
  nameContains = signal('');

  constructor() {
    this.roleSearch = computed(() => ({
        nameContains: this.nameContains(),
        nameNlContains: this.nameNlContains(),
      })
    );

    this.pagination = this.paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.rolePage = derivedAsync(() => this.roleService.findRoles$(this.roleSearch(), this.pagination()));
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(role: Role): Role {
    return role;
  }



}


