import { Component, inject, Injector, Input } from '@angular/core';
import {Reception} from '@model/reception';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Observable, shareReplay} from 'rxjs';
import {Stock} from '@model/stock';
import {StockService} from '@services/stock.service';
import {Page} from '@typedefs/page';
import { NgIf, AsyncPipe } from '@angular/common';
import { PalletLabelsComponent } from '../reception-item-labels/pallet-labels.component';

@Component({
    selector: 'foodbank-reception-labels',
    templateUrl: './reception-labels.component.html',
    styleUrl: './reception-labels.component.scss',
    imports: [NgIf, PalletLabelsComponent, AsyncPipe]
})
export class ReceptionLabelsComponent {

  @Input()
  reception?: Reception

  stocks$: Observable<Page<Stock>>
  #injector = inject(Injector)

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig<Reception>,
              private stockService: StockService) {
    this.reception = this.config.data as Reception;

    this.stocks$ = this.stockService.findStock({ receptionId: this.reception?.id }, {
      page: 0,
      size: 1000
    }, this.#injector).pipe(shareReplay());
  }

  close() {
    this.ref.close({});
  }
}
