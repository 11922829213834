import { inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, Observable } from 'rxjs';
import { Page } from '@typedefs/page';
import { environment } from '@environments/environment';
import { Pagination } from './pagination';
import { StockGroupDto, StockGroupWithSummaryDto } from '@typedefs/stock-rest';
import { copyCommonFields } from "@model/mapping-utils";
import { StockGroup } from "@model/stock-group";
import { StockGroupSearch } from "@model/search/stock-group-search";
import { FoodbankCache } from "@services/foodabank-cache";
import { FoodbankCacheFactory } from "@services/foodabank-cache-factory";
import { StockGroupWithSummary } from "@model/stock-group-with-summary";
import { StockSummaryService } from "@services/stock-summary.service";
import { StockSearchMappingService } from "@services/stock-search-mapping.service";

@Injectable({
  providedIn: 'root'
})
export class StockGroupService {

  #httpClient = inject(HttpClient);
  #stockSummaryService = inject(StockSummaryService);
  #stockSearchMappingService = inject(StockSearchMappingService);
  #foodbankCacheFactory = inject(FoodbankCacheFactory)

  getStockGroup$(stockGroupId: number, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<StockGroup> {
    return this.#httpClient.get<StockGroupDto>(`${environment.apiUrl}/stocks/groups/${stockGroupId}`)
      .pipe(
        map(stockGroupDto => this.mapToStockGroup(stockGroupDto, cache)),
      );
  }

  findStockGroups$(stockGroupSearch: StockGroupSearch, pagination: Pagination, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<StockGroup>> {
    const stockGroupSearchDto = this.#stockSearchMappingService.mapToStockGroupSearchDto(stockGroupSearch);

    return this.#httpClient.post<Page<StockGroupDto>>(`${environment.apiUrl}/stocks/groups/search`, stockGroupSearchDto, {params: pagination})
      .pipe(map(stockGroupDtoPage => {
        const stockGroupDtos: StockGroup[] = stockGroupDtoPage.content.map(stockGroupDto => this.mapToStockGroup(stockGroupDto, cache));
        return {
          ...stockGroupDtoPage,
          content: stockGroupDtos
        }
      }));
  }

  findStockGroupWithSummaryPage$(stockGroupSearch: StockGroupSearch, pagination: Pagination, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<StockGroupWithSummary>> {
    const stockGroupSearchDto = this.#stockSearchMappingService.mapToStockGroupSearchDto(stockGroupSearch);

    return this.#httpClient.post<Page<StockGroupWithSummaryDto>>(`${environment.apiUrl}/stocks/groups/summary/search`, stockGroupSearchDto, {params: pagination})
      .pipe(map(stockGroupDtoPage => {
        const stockGroupWithSummaryDtos: StockGroupWithSummary[] = stockGroupDtoPage.content.map(stockGroupWithSummaryDto => this.mapToStockGroupWithSummary(stockGroupWithSummaryDto, cache));
        return {
          ...stockGroupDtoPage,
          content: stockGroupWithSummaryDtos
        }
      }));
  }


  mapToStockGroup(stockGroupDto: StockGroupDto, cache: FoodbankCache): StockGroup {
    const commonFields: Partial<StockGroup> = copyCommonFields(stockGroupDto, ['articleId', 'supplierId', 'receptionId', 'palletTypeId']);

    return {
      ...commonFields,
      article: cache.articleCache.get(stockGroupDto.articleId),
      supplier: cache.supplierCache.get(stockGroupDto.supplierId),
      reception: cache.receptionCache.getIfDefined(stockGroupDto.receptionId),
      palletType: cache.palletTypeCache.getIfDefined(stockGroupDto.palletTypeId),
    }
  }

  mapToStockGroupWithSummary(stockGroupWithSummaryDto: StockGroupWithSummaryDto, cache: FoodbankCache): StockGroupWithSummary {
    const commonFields: StockGroupWithSummary | StockGroupWithSummaryDto = copyCommonFields(stockGroupWithSummaryDto, ['stockGroupDto', 'stockSummaryDto']);
    return {
      ...commonFields,
      stockGroup: this.mapToStockGroup(stockGroupWithSummaryDto.stockGroupDto, cache),
      stockSummary: this.#stockSummaryService.mapToStockSummary(stockGroupWithSummaryDto.stockSummaryDto),
    };
  }
}
