import { Component, computed, inject, Injector, signal, Signal } from '@angular/core';
import { Warehouse } from '@model/warehouse';
import { WarehouseSearch } from '@model/search/warehouse-search';
import { BehaviorSubject, combineLatestWith, distinctUntilChanged, filter, map, Observable, of } from 'rxjs';
import { UserService } from '@services/user.service';
import { Company } from '@model/company';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ArticleSearch } from '@model/search/article-search';
import { Article } from '@model/article';
import { FeadPlanningByOrganization } from '@model/fead-planning-by-organization';
import { ArticleFeadPlanningService } from '@services/article-fead-planning.service';
import { FeadCampaignService } from '@services/fead-campaign.service';
import { FeadCampaign } from '@model/fead-campaign';
import { Page } from '@typedefs/page';
import { FeadCampaignIdentifier } from '@model/fead-campaign-identifier';
import { CompanyStatus } from '@typedefs/stock-rest';
import { StockService } from '@services/stock.service';
import { StockSearch } from '@model/search/stock-search';
import { AsyncPipe, DecimalPipe, NgIf } from '@angular/common';
import { WarehouseSingleSelectionComponent } from '../../warehouse/selection/single/warehouse-single-selection.component';
import { FormsModule } from '@angular/forms';
import { FeadPlanningOrganizationTypeSelectionComponent } from './fead-planning-organization-type-selection/fead-planning-organization-type-selection.component';
import { FeadCampaignSelectionComponent } from '../../fead-campaign/fead-campaign-selection/fead-campaign-selection.component';
import { ArticleSingleSelectionComponent } from '../../article/selection/single/article-single-selection.component';
import { DropdownModule } from 'primeng/dropdown';
import { Button } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FeadPlanningArticleTableComponent } from './table/fead-planning-article-table.component';
import { derivedAsync } from "ngxtension/derived-async";
import { toSignal } from "@angular/core/rxjs-interop";

@Component({
    selector: 'foodbank-fead-planning-article',
    templateUrl: './fead-planning-article.component.html',
    styleUrls: ['./fead-planning-article.component.scss'],
  imports: [NgIf, WarehouseSingleSelectionComponent, FormsModule, FeadPlanningOrganizationTypeSelectionComponent, FeadCampaignSelectionComponent, ArticleSingleSelectionComponent, DropdownModule, Button, ConfirmDialogModule, FeadPlanningArticleTableComponent, AsyncPipe, DecimalPipe]
})
export class FeadPlanningArticleComponent {

  // user selected or entered values
  warehouseSearch: Signal<WarehouseSearch | undefined>;
  articleSearch: Signal<ArticleSearch | undefined>;

  warehouse$: BehaviorSubject<Warehouse | undefined> = new BehaviorSubject<Warehouse | undefined>(undefined);
  campaign$: BehaviorSubject<FeadCampaignIdentifier | undefined> = new BehaviorSubject<FeadCampaignIdentifier | undefined>(undefined);
  associationType$: BehaviorSubject<CompanyStatus | undefined> = new BehaviorSubject<CompanyStatus | undefined>('NON_PROFIT');
  article$: BehaviorSubject<Article | undefined> = new BehaviorSubject<Article | undefined>(undefined);
  round$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  // loaded data
  planningData$: Observable<FeadPlanningByOrganization[]> | undefined;
  articleRoundOptions$ = new BehaviorSubject<number[]>([1]);
  articleStockData: Signal<{ quantity: number; pallets: number; units: number; parcels: number }> = signal({quantity: 0, units: 0, pallets: 0, parcels: 0});

  updatedData: FeadPlanningByOrganization[] | undefined;

  #injector = inject(Injector);

  constructor(
    private userService: UserService,
    private articleFeadPlanningService: ArticleFeadPlanningService,
    private feadCampaignService: FeadCampaignService,
    private stockService: StockService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {

    const currentUserCompany = this.userService.getCurrentUserCompany();
    const defaultWarehouse$ = this.userService.getDefaultWarehouse$();
    defaultWarehouse$.subscribe(defaultWarehouse => this.selectWarehouse(defaultWarehouse!));
    this.warehouseSearch = derivedAsync(() => this.getWarehouseSearch(currentUserCompany()));
    const selectedWarehouse$ = this.warehouse$.pipe(filter(warehouse => !!warehouse));
    const selectedWarehouse = toSignal(selectedWarehouse$);
    const selectedAssociationType = this.associationType$.pipe(filter(associationType => !!associationType));
    const selectedCampaign$ = this.campaign$.pipe(filter(campaign => !!campaign));
    const selectedArticle$ = this.article$.pipe(filter(article => !!article));
    const selectedRound = this.round$.pipe(filter(round => !!round));
    const selectedArticle = toSignal(selectedArticle$);


    selectedWarehouse$.pipe(
      combineLatestWith(selectedCampaign$, selectedArticle$),
      distinctUntilChanged()
    )
      .subscribe(([warehouse, campaign, article]) => {
        this.loadRoundOptions(warehouse!.id, article!.id, campaign!.year);
      });

    selectedWarehouse$.pipe(
      combineLatestWith(selectedAssociationType, selectedCampaign$, selectedArticle$, selectedRound),
      distinctUntilChanged())
      .subscribe(([warehouse, associationType, campaign, article, round]) => {
        this.fetchPlanningData(warehouse!, associationType!, campaign!.year, article!, round!);
      });

    this.articleSearch = computed(() => ({
      fead: true,
      stockGroupSearch: {
        stockSearch: {
          warehouseSearch: {
            warehouses: selectedWarehouse() ? [selectedWarehouse()!] : []
          },
        }
      }
    }))

    const stockPage = derivedAsync(() => {

      const selectedWarehouseValue = selectedWarehouse();
      const selectedArticleValue = selectedArticle();

      if (!selectedWarehouseValue || !selectedArticleValue) {
        return of(undefined);
      }

      const stockSearch: StockSearch = {
        warehouseSearch: {
          warehouses: [selectedWarehouseValue!]
        },
        articleSearch: {
          articles: [selectedArticleValue!]
        }
      };

      const pagination = {page: 0, size: 1000};
      return this.stockService.findStock(stockSearch, pagination, this.#injector);
    });

    this.articleStockData = computed(() => {
      const stockPageValue = stockPage()
      if (!stockPageValue) {
        return { quantity: 0, units: 0, pallets: 0, parcels: 0 };
      }

      const quantity = stockPageValue.content.reduce((acc, stock) => acc + stock.quantity, 0)
      const units = stockPageValue.content.reduce((acc, stock) => acc + (stock.unitWeight === 0 ? 0 : (stock.quantity * 1000 / stock.unitWeight)), 0)
      const parcels = stockPageValue.content.reduce((acc, stock) => acc + (stock.unitWeight === 0 ? 0 : (stock.quantity * 1000 / stock.unitWeight / stock.unitsPerParcel)), 0)

      return {
        quantity,
        units,
        parcels,
        pallets: stockPageValue.totalElements
      };
    });
  }

  selectWarehouse(warehouse?: Warehouse) {
    this.warehouse$.next(warehouse);
  }

  selectArticle(article?: Article) {
    console.log(article)
    this.article$.next(article);
  }

  selectCampaign(campaign: FeadCampaignIdentifier) {
    this.campaign$.next(campaign)
  }

  selectAssociationType(associationType: CompanyStatus) {
    this.associationType$.next(associationType)
  }

  selectRound(round: number) {
    this.round$.next(round);
  }

  save() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to save this planning?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Save',
      rejectLabel: 'Cancel',
      accept: () => {
        const warehouse = this.warehouse$.getValue()!;
        const year = this.campaign$.getValue()!.year;
        const article = this.article$.getValue()!;
        const planningData = this.updatedData!;
        this.articleFeadPlanningService.saveArticleFeadPlanning$(warehouse, year, article, planningData)
          .subscribe(() => {
            this.messageService.add({severity: 'success', summary: 'Saved', detail: 'Planning data has been saved.'});
            this.reloadPlanningData();
          });
      }
    });
  }

  cancel() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this planning?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Reload',
      rejectLabel: 'Stay',
      accept: () => {
        this.reloadPlanningData();
        this.messageService.add({severity: 'warn', summary: 'Canceled', detail: 'Planning data has been reset.'});
      }
    });
  }

  delete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this planning?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        this.articleFeadPlanningService.deleteArticlePlanning$(this.warehouse$.getValue()!, this.campaign$.getValue()!.year, this.article$.getValue()!)
          .subscribe(() => {
            this.messageService.add({severity: 'info', summary: 'Deleted', detail: 'Planning data has been reset.'});
            this.reloadPlanningData();
          });
      }
    });
  }

  private getWarehouseSearch(company: Company | undefined): WarehouseSearch | undefined {
    if (!company) {
      return undefined;
    }

    return {
      company: company
    }
  }

  private getArticleSearch(campaign?: FeadCampaignIdentifier): ArticleSearch {
    return {
      feadYear: campaign?.year
    }
  }

  private reloadPlanningData() {
    this.fetchPlanningData(this.warehouse$.getValue()!, this.associationType$.getValue()!, this.campaign$.getValue()!.year, this.article$.getValue()!, this.round$.getValue()!);
  }

  private fetchPlanningData(warehouse: Warehouse, companyStatus: CompanyStatus, year: number, article: Article, round: number) {
    this.planningData$ = this.articleFeadPlanningService.getArticleFeadPlanning$(warehouse, companyStatus, year, article, round);
    this.planningData$.subscribe(data => this.planningDataUpdates(data));
  }

  planningDataUpdates(planningData: FeadPlanningByOrganization[]) {
    this.updatedData = planningData;
  }

  private loadRoundOptions(organizationId: number, articleId: string, year: number) {
    const pagination = {size: 1, page: 1};
    const feadCampaignSearch = {organizationId, articleId, year};

    this.feadCampaignService.findFeadCampaigns$(pagination, feadCampaignSearch)
      .pipe(map(page => this.getCampaignRounds(page)))
      .subscribe(campaignRounds => {
        this.articleRoundOptions$.next(campaignRounds);

        if (campaignRounds.length > 0) {
          this.round$.next(campaignRounds[0]);
        }
      });
  }

  private getCampaignRounds(page: Page<FeadCampaign>): number[] {
    const pageRounds = page.content.length > 0 && !!page.content[0] ? page.content[0].rounds : 0
    const campaignRounds = pageRounds < 1 ? 1 : pageRounds;
    return Array.from({length: campaignRounds}, (_, i) => i + 1);
  }
}
