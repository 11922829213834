import {Component, computed, inject, input, model, Signal} from '@angular/core';
import {LanguageService} from '@services/language.service';
import {Article} from '@model/article';
import {toSignal} from "@angular/core/rxjs-interop";
import {MenuItem} from "primeng/api";
import {Router} from "@angular/router";
import {ContextMenuModule} from "primeng/contextmenu";
import {NgIf} from "@angular/common";
import {LanguageDirective} from "@directives/language.directive";

@Component({
    selector: 'foodbank-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss'],
    imports: [
        ContextMenuModule,
        NgIf,
        LanguageDirective,
    ]
})
export class ArticleComponent {

  ngModel = model<Article>();

  showId = input(true);
  doShowId: Signal<boolean>;

  contextMenuItems: MenuItem[];

  private readonly languageService = inject(LanguageService);
  private readonly router = inject(Router);

  constructor() {
    const currentLanguageDisplayable$ = this.languageService.isCurrentLanguageDisplayable$()
    const currentLanguageDisplayable = toSignal(currentLanguageDisplayable$);
    this.doShowId = computed(() => currentLanguageDisplayable() ? this.showId() : true);

    this.contextMenuItems = [
      {
        label: 'Show',
        icon: 'pi pi-palette',
        command: () => this.router.navigate(['articles'])
      },
    ];
  }

}
