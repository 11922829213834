<p-table #dt
         [value]="(organizationPage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="organizationPage.value()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
       <span class="flex">
        <h2 class="m-0">Organizations</h2>
        <p-multiSelect
          class="ml-2"
          [options]="allColumnsWithDefaultConfig()"
          [(ngModel)]="selectedColumns"
          optionLabel="label"
          dataKey="field"
          selectedItemsLabel="{{allColumnsWithDefaultConfig().length === selectedColumns().length ? 'All' : selectedColumns().length + '/' + allColumnsWithDefaultConfig().length}} columns selected"
          [style]="{'min-width': '200px'}"
          placeholder="Choose Columns" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="name">
        <span i18n>Name</span>
        <p-sortIcon field="familyName"/>
      </th>
      <th *ngIf="columnsConfig().email?.visible" pSortableColumn="email">
        <span i18n>Email</span>
        <p-sortIcon field="email"/>
      </th>
      <th *ngIf="columnsConfig().phone?.visible">
        <span i18n>Phone</span>
      </th>
      <th *ngIf="columnsConfig().address?.visible">
        <span i18n>Address</span>
      </th>
      <th *ngIf="columnsConfig().zip?.visible"  pSortableColumn="zip">
        <span i18n>Zip</span>
        <p-sortIcon field="zip"/>
      </th>
      <th *ngIf="columnsConfig().city?.visible" pSortableColumn="city">
        <span i18n>City</span>
        <p-sortIcon field="city"/>
      </th>
    </tr>
    <tr>
      <th>
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterNameContains" ngDefaultControl/>
      </th>
      <th *ngIf="columnsConfig().email?.visible" pSortableColumn="email">

      </th>
      <th *ngIf="columnsConfig().phone?.visible">

      </th>
      <th *ngIf="columnsConfig().address?.visible">

      </th>
      <th *ngIf="columnsConfig().zip?.visible" >
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterZipContains" ngDefaultControl/>
      </th>
      <th *ngIf="columnsConfig().city?.visible" >
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterCityContains" ngDefaultControl/>
      </th>

    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-organization>
    <ng-container *ngIf="identity(organization) as organization">
      <tr>
        <td>
          {{ organization.name }}
        </td>
        <td *ngIf="columnsConfig().email?.visible">
          {{ organization.email }}
        </td>
        <td *ngIf="columnsConfig().phone?.visible">
          {{ generateTelGsm(organization) }}
        </td>
        <td *ngIf="columnsConfig().address?.visible">
          <ng-container *ngIf="getAddress(organization) as completeAddress">
            <a href="http://maps.google.com/maps?q={{ completeAddress }}" target="_blank">
              {{ completeAddress }}
            </a>
          </ng-container>
        </td>
        <td *ngIf="columnsConfig().zip?.visible">
          {{ organization.zip }}
        </td>
        <td *ngIf="columnsConfig().city?.visible">
          {{ organization.city }}
        </td>
        <td>
          <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(organization)"></button>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>

