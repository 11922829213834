<div #tableContainer>
  <ng-container *ngIf="preparationSelectionPage() as preparationSelectionPage">
    <p-table #dt
             [value]="preparationSelectionPage.content"
             responsiveLayout="scroll"
             [rows]="DEFAULT_ROWS_PER_PAGE"
             [totalRecords]="preparationSelectionPage.totalElements"
             [globalFilterFields]="['depot']"
             [paginator]="true"
             [rowsPerPageOptions]="[15,25,50,100]"
             [showCurrentPageReport]="true"
             [lazy]="true"
             (onLazyLoad)="loadPreparationSelectionList($event)"
             [rowHover]="true"
             [resizableColumns]="true"
             columnResizeMode="expand"
             sortMode="multiple"
             selectionMode="multiple"
             [selection]="selectedPreparationSelections"
             (selectionChange)="notifySelectionChange($event)"
             dataKey="id"
             [multiSortMeta]="[{ field: 'BEST_BEFORE_DATE', order: -1 }, { field: 'ARTICLE_ID', order: 1 }]"
             editMode="cell"
             [styleClass]="tableSizeStyleClass()"
             [scrollable]="true"
             [scrollHeight]="tableHeight"
    >

      <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h3 class="mb-2 ml-2" i18n>Stock to prepare</h3>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr style="vertical-align: bottom">
          <th *ngIf="selectionEnabled()">
            <p-tableHeaderCheckbox  />
          </th>
          <th>#</th>
          <th pSortableColumn="ARTICLE_STORAGE_CONDITION_ID" pResizableColumn>
            <span i18n>Storage</span>
            <p-sortIcon field="ARTICLE_STORAGE_CONDITION_ID" />
          </th>
          <th pSortableColumn="ARTICLE_ID" pResizableColumn>
            <span i18n>Article</span>
            <p-sortIcon field="ARTICLE_ID" />
          </th>
          <th>
            <span i18n>Description</span>
          </th>
          <th>
            <span i18n>Internal batch</span>
          </th>
          <th>
            <span i18n>Net Weight (Kg)</span>
          </th>
          <th>
            <span i18n>Taken quantity (Kg)</span>
          </th>
          <th>
            <span i18n>{{ selectedPackageDistribution() === 'UNIT' ? 'Units' : 'Parcels' }}</span>
          </th>
          <th>
            <span i18n>Taken {{ selectedPackageDistribution() === 'UNIT' ? 'units' : 'parcels' }}</span>
          </th>
          <th pSortableColumn="BEST_BEFORE_DATE">
            <span i18n>Best before date</span>
            <p-sortIcon field="BEST_BEFORE_DATE" />
          </th>
          <th>
            <span i18n>Deliver before date</span>
          </th>
          <th>
            <span i18n>Location</span>
          </th>
          <th>
            <span i18n pSortableColumn="PALLET_ID">Pallet id</span>
          </th>
          <th>
            <span i18n>Net unit weight (gr)</span>
          </th>
          <th>
            <span i18n>Units per parcel</span>
          </th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>
            <p-columnFilter field="storageConditions" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-articleStorageConditions let-filter="filterCallback">
                <foodbank-article-storage-condition-selection [selectedArticleStorageConditions]="articleStorageConditions"
                  [articleStorageConditionSearch]="filterArticleStorageConditionSearch() || {}"
                  (onArticleStorageConditionSelected)="selectedArticleStorageConditions.set(articleStorageConditions)"
                 />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="articles" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-selectedArticles let-filter="filterCallback">
                <foodbank-article-multi-selection [selectedArticles]="selectedArticles"
                  [articleSearch]="filterArticleSearch()"
                  (onArticleSelected)="selectedArticles.set(selectedArticles)"
                 />
              </ng-template>
            </p-columnFilter>
          </th>
          <th colspan="6"></th>
          <th>
            <p-columnFilter field="bestBeforeDate" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <foodbank-date-picker id="best-before-date" name="best-before-date"
                                      [ngModel]="selectedBestBeforeDateRange()"
                                      (onSelect)="selectedBestBeforeDateRange.set($event.value)"
                                      [showSelectionOptions]="true"
                                      pTooltip="Single: Less than or equal to selected date.

                                  Range: Between selected dates (inclusive)."
                                      ngDefaultControl
                 />
              </ng-template>
            </p-columnFilter>

          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-stockPreparationItem let-index="rowIndex" let-editing="editing">
        <ng-container *ngIf="identity(stockPreparationItem) as stockPreparationItem">
          <ng-container *ngIf="stockIdentity(stockPreparationItem.stock$ | async) as stock">
            <tr pDraggable [pDraggableDisabled]="!isStockDraggable(stockPreparationItem)" (onDragStart)="notifyStockDragStart($event, stockPreparationItem)" (onDragEnd)="notifyStockDragEnd($event)">
              <td *ngIf="selectionEnabled()">
                <p-tableCheckbox [value]="stockPreparationItem" />
              </td>
              <td>{{ index + 1 }}</td>
              <td>
                <foodbank-article-storage-condition [articleStorageCondition]="(stock.article$ | async)?.articleStorageCondition$ | async" />
              </td>
              <td class="white-space-normal">
                <foodbank-article ngDefaultControl [ngModel]="(stock.article$ | async) || undefined" />
              </td>
              <td class="white-space-normal">
                {{ stock.stockGroup.value()?.fullDescription }}
              </td>
              <td>
                {{ stock.internalBatch }}
              </td>
              <td class="aligned-quantity">
                {{ (stock.palletSummary$ | async)?.netWeight | number: '1.2-2' }}
              </td>
              <td class="aligned-quantity" pEditableColumn pEditableColumnField="takenQuantity">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input id="stock-{{index}}-taken-quantity" name="stock-{{index}}-taken-quantity" pInputText type="text"
                           [class.ng-invalid]="!(stockPreparationItem.valid$ | async)"
                           [(ngModel)]="stockPreparationItem.dispatchWeight" (ngModelChange)="onTakenQuantityChanged(stockPreparationItem)" ngDefaultControl/>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <div class="aligned-quantity" [class.editable]="editing" [class.text-red-600]="!(stockPreparationItem.valid$ | async)">
                      <span>{{ stockPreparationItem.dispatchWeight | number: '1.2-2' }}</span>
                      <i class="fa-regular fa-pen-to-square ml-1"></i>
                    </div>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td class="aligned-quantity">
                <span *ngIf="selectedPackageDistribution() === 'UNIT'">{{ (stock.palletSummary$ | async)?.unitCount | number: '1.2-2' }}</span>
                <span *ngIf="selectedPackageDistribution() === 'PARCEL'">{{ (stock.palletSummary$ | async)?.parcelCount | number: '1.0-0' }}</span>
              </td>
              <td *ngIf="selectedPackageDistribution() === 'UNIT'" class="aligned-quantity" pEditableColumn pEditableColumnField="takenUnits">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input id="stock-{{index}}-taken-units" name="stock-{{index}}-taken-units" pInputText type="number"
                           [class.ng-invalid]="!(stockPreparationItem.valid$ | async)"
                           [(ngModel)]="stockPreparationItem.dispatchUnitCount" (ngModelChange)="onTakenUnitsChanged(stockPreparationItem)" ngDefaultControl/>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <div *ngIf="stock.unitWeight > 0" class="aligned-quantity" [class.editable]="editing" [class.text-red-600]="!isInteger(stockPreparationItem.dispatchUnitCount)">
                      <span>{{ stockPreparationItem.dispatchUnitCount | number: '1.2-2' }}</span>
                      <i class="fa-regular fa-pen-to-square ml-1"></i>
                    </div>
                    <span *ngIf="stock.unitWeight <= 0" pTooltip="Unit weight is 0">N/A</span>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td *ngIf="selectedPackageDistribution() === 'PARCEL'" class="aligned-quantity" pEditableColumn pEditableColumnField="takenParcels">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input id="stock-{{index}}-taken-parcels" name="stock-{{index}}-taken-parcels" pInputText type="number"
                         [class.ng-invalid]="!(stockPreparationItem.valid$ | async)"
                         [(ngModel)]="stockPreparationItem.dispatchParcelCount" (ngModelChange)="onTakenParcelsChanged(stockPreparationItem)" ngDefaultControl/>
                </ng-template>
                <ng-template pTemplate="output">
                  <div *ngIf="stock.unitWeight > 0" class="aligned-quantity" [class.editable]="editing" [class.text-red-600]="!isInteger(stockPreparationItem.dispatchParcelCount)">
                    <span>{{ stockPreparationItem.dispatchParcelCount | number: '1.2-2' }}</span>
                    <i class="fa-regular fa-pen-to-square ml-1"></i>
                  </div>
                  <span *ngIf="stock.unitWeight <= 0" pTooltip="Unit weight is 0">N/A</span>
                </ng-template>
              </p-cellEditor>
            </td>
              <td>{{ stock.bestBeforeDate | date: 'dd/MM/yyyy' }}</td>
              <td>{{ stock.stockGroup.value()?.deliverBeforeDate | date: 'dd/MM/yyyy' }}</td>
              <td>
                {{ stock.location }}
              </td>
              <td>
                <foodbank-pallet [pallet]="(stock.pallet$ | async) || undefined" [showHistory]="true" />
              </td>
              <td class="aligned-quantity">
                {{ stock.unitWeight | number: '1.2-2' }}
              </td>
              <td class="aligned-quantity">
                {{ stock.unitsPerParcel | number: '1.2-2' }}
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="19"><span i18n>{{ emptyMessage }}</span></td>
        </tr>

      </ng-template>
    </p-table>
  </ng-container>
</div>
