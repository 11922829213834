import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {RoleDto, RoleSearchDto} from '@typedefs/stock-rest';
import {Role} from "@model/role";
import {RoleSearch} from "@model/search/role-search";
import {FoodbankCache} from "@services/foodabank-cache";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public getRoles$(): Observable<Role[]> {
    return this.#httpClient.get<Role[]>(`${environment.apiUrl}/roles`)
      .pipe(shareReplay());
  }

  public getRole$(id: number, injector: Injector, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Role> {
    return this.#httpClient.get<RoleDto>(`${environment.apiUrl}/roles/${id}`)
      .pipe(
        map(roleDto => this.mapToRole(roleDto, cache)),
        shareReplay(),
      );
  }

  public findRoles$(roleSearch: RoleSearch, pagination: Pagination, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Page<Role>> {
    const roleSearchDto = this.mapToRoleSearchDto(roleSearch);
    return this.#httpClient.post<Page<RoleDto>>(`${environment.apiUrl}/roles/search`, roleSearchDto, {params: pagination})
      .pipe(map(rolePage => this.loadRolePage(rolePage, cache)));
  }

  private loadRolePage(roleDtoPage: Page<RoleDto>, cache: FoodbankCache): Page<Role> {
    return {
      ...roleDtoPage,
      content: this.loadRoleDetailsList(roleDtoPage.content, cache)
    };
  }

  public loadRoleDetailsList(roleDtos: RoleDto[], cache: FoodbankCache) {
    return roleDtos.map(role => this.mapToRole(role, cache));
  }

  updateRole(role: Role): Observable<RoleDto> {
    // FIXME: convert to RoleDto
    return this.#httpClient.put<RoleDto>(`${environment.apiUrl}/roles/${role.id}`, role)
  }

  public mapToRole(roleDto: RoleDto, cache: FoodbankCache): Role {
    return {
      ...roleDto,

    };
  }

  mapToRoleSearchDto(roleSearch: RoleSearch): RoleSearchDto {
    return {
      ...roleSearch,
    };
  }

}
