import {Directive, effect, inject, TemplateRef, ViewContainerRef} from '@angular/core';
import {LanguageService} from '@services/language.service';
import {derivedAsync} from "ngxtension/derived-async";

@Directive({
  selector: '[foodbankUnsupportedLanguage]',
  standalone: true,
})
export class UnsupportedLanguageDirective {

  #viewRef = inject(ViewContainerRef);
  #template = inject(TemplateRef<any>);
  #languageService = inject(LanguageService);

  constructor() {
    const displayable = derivedAsync(() => this.#languageService.isCurrentLanguageDisplayable$());
    // avoid effect generally, though this is the right thing to do here
    effect(() => {
      if (!displayable()) {
        this.#viewRef.clear();
        this.#viewRef.createEmbeddedView(this.#template);
      } else {
        this.#viewRef.clear();
      }
    });
  }

}
