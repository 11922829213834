import { Component, computed, inject, input, model, signal, Signal } from '@angular/core';
import { debounceTime, defaultIfEmpty, EMPTY, map, pipe, switchMap } from 'rxjs';
import { MultiSelectFilterEvent, MultiSelectModule } from "primeng/multiselect";
import { FormsModule } from "@angular/forms";
import { pipeSignal } from "@util/foodbanks-signal-rxjs-interop";
import { SupplierBatchSearch } from "@model/search/supplier-batch-search";
import { StockSearchService } from "@services/stock-search.service";

@Component({
  selector: 'foodbank-supplier-batch-multi-selection',
  templateUrl: './supplier-batch-multi-selection.component.html',
  styleUrls: ['./supplier-batch-multi-selection.component.scss'],
  imports: [
    MultiSelectModule,
    FormsModule
  ]
})
export class SupplierBatchMultiSelectionComponent {

  ngModel = model<string[]>([]);
  supplierBatchSearch = input<SupplierBatchSearch>();

  supplierBatches: Signal<string[] | undefined>
  supplierBatchContains = signal<string | undefined>(undefined);

  #stockSearchService = inject(StockSearchService);

  constructor() {
    const debouncedSupplierBatchContains = pipeSignal(this.supplierBatchContains, debounceTime(300));
    const actualSupplierBatchSearch = computed(() => ({
      ...this.supplierBatchSearch(),
      supplierBatchContains: debouncedSupplierBatchContains()
    }));

    this.supplierBatches = pipeSignal(actualSupplierBatchSearch, pipe(
      switchMap(supplierBatchSearch => !supplierBatchSearch ? EMPTY : this.#stockSearchService.findSupplierBatches(supplierBatchSearch, {page: 0, size: 1000})),
      map(page => page.content),
      defaultIfEmpty([]))
    );
  }

  filterSupplierBatches($event: MultiSelectFilterEvent) {
    this.supplierBatchContains.set($event.filter);
  }
}
