<p-dropdown [(ngModel)]="ngModel"
            [options]="roles()"
            optionLabel="name"
            placeholder="None"
            [required]="required()"
            [filter]="true"
            (onClear)="clear()"
            [showClear]="true"
            dataKey="id"
            appendTo="body">
  <ng-template pTemplate="filter">
    <input type="text" pInputText pAutoFocus
           [autofocus]="true"
           placeholder="search"
           [(ngModel)]="nameFilter"
    />
  </ng-template>
  <ng-template pTemplate="selectedItem">
    <foodbank-role ngDefaultControl [ngModel]="ngModel()" />
  </ng-template>
  <ng-template let-role pTemplate="item">
    <foodbank-role ngDefaultControl [ngModel]="role" />
  </ng-template>
</p-dropdown>
