<div class="flex flex-col align-items-center gap-3 mb-4">
  <label for="fileUpload" class="block text-2xl font-medium">{{ hasData() ? 'Imported data' : 'Upload Excel file' }}</label>
  <ng-container *ngIf="!hasData(); else clearButton">
    <p-fileUpload
      mode="basic"
      chooseLabel="Choose"
      chooseIcon="pi pi-upload"
      name="demo[]"
      url="https://www.primefaces.org/cdn/api/upload.php"
      maxFileSize="1000000"
      (onUpload)="onUpload($event)"
    />
  </ng-container>
  <ng-template #clearButton>
    <p-button icon="pi pi-trash" severity="danger" (onClick)="clearData()" label="Clear data"></p-button>
  </ng-template>
</div>
<div class="flex flex-col gap-2 mb-3" *ngIf="hasData()">
  <div class="text-lg" [class.text-red-500]="problemRows().length > 0">{{ statusMessage() }}</div>
  <div class="flex align-items-center gap-2" *ngIf="problemRows().length > 0">
    <p-checkbox [(ngModel)]="showOnlyProblems" [binary]="true" inputId="problems"></p-checkbox>
    <label for="problems" class="cursor-pointer">Show only rows with problems</label>
  </div>
</div>
<p-table
         loadingIcon="pi pi-spin pi-spinner big-loading-icon"
         [value]="filteredData() || []"
         [sortField]="'rowNumber'"
         [sortOrder]="1"
         [multiSortMeta]="[{field: 'rowNumber', order: 1}]"
         [sortMode]="'multiple'"
         class="p-datatable-gridlines p-datatable-sm"
         [totalRecords]="data().length || 0"
         [showCurrentPageReport]="true"
         [paginator]="true"
         [rows]="10"
         [rowsPerPageOptions]="[10, 25, 50, 100]"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         >
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="rowNumber">#<p-sortIcon field="rowNumber"></p-sortIcon></th>
      <th pSortableColumn="yearMonth">Year month<p-sortIcon field="yearMonth"></p-sortIcon></th>
      <th pSortableColumn="supplierName">Supplier Name<p-sortIcon field="supplierName"></p-sortIcon></th>
      <th pSortableColumn="supplierId">Supplier Id<p-sortIcon field="supplierId"></p-sortIcon></th>
      <th pSortableColumn="postalCode">Postal code<p-sortIcon field="postalCode"></p-sortIcon></th>
      <th pSortableColumn="city">City<p-sortIcon field="city"></p-sortIcon></th>
      <th pSortableColumn="productCode">Product code<p-sortIcon field="productCode"></p-sortIcon></th>
      <th pSortableColumn="productId">Food id<p-sortIcon field="productId"></p-sortIcon></th>
      <th pSortableColumn="article">Article<p-sortIcon field="article"></p-sortIcon></th>
      <th pSortableColumn="unit">Unit<p-sortIcon field="unit"></p-sortIcon></th>
      <th pSortableColumn="quantity">Quantity<p-sortIcon field="quantity"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-index="rowIndex">
    <tr>
      <td>{{ item.rowNumber }}</td>
      <td>{{ item.yearMonth }}</td>
      <td>{{ item.supplierName }}</td>
      <td>{{ item.supplierId }}</td>
      <td>{{ item.postalCode }}</td>
      <td>{{ item.city }}</td>
      <td>{{ item.productCode }}</td>
      <td>
        <div class="flex align-items-center">
          <input pInputText type="text" [ngModel]="item.productId"
                 (ngModelChange)="onFoodIdChange(item, $event)"
                 class="p-inputtext-sm w-8rem">
          <i [class]="item.articleRef.value() ? 'pi pi-check text-green-500' : 'pi pi-times text-red-500'"
             [pTooltip]="item.articleRef.value() ? 'Article found' : 'Article not found'"
             class="ml-2">
          </i>
        </div>
      </td>
      <td>{{ item.article }}</td>
      <td>{{ item.unit }}</td>
      <td pEditableColumn pEditableColumnField="quantity">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="number" [ngModel]="item.quantity"
                   (ngModelChange)="onQuantityChange(item, $event)"
                   class="p-inputtext-sm w-8rem"
                   min="0">
          </ng-template>
          <ng-template pTemplate="output">
            <div class="editable">
              <foodbank-number [value]="item.quantity" [decimals]="3" />
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
    </tr>
  </ng-template>
</p-table>
