import { Injectable } from "@angular/core";
import { StockSummaryDto } from "@typedefs/stock-rest";
import { StockSummary } from "@model/stock-summary";
import { copyCommonFields } from "@model/mapping-utils";

@Injectable({
  providedIn: 'root'
})
export class StockSummaryService {

  mapToStockSummary(stockSummaryDto: StockSummaryDto): StockSummary {
    const commonFields: StockSummaryDto | StockSummary = copyCommonFields(stockSummaryDto, []);

    return {...commonFields};
  }

}
