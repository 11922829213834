<div class="card p-fluid" *ngIf="organization() as organization">

  <div class="field grid">
    <label for="organization-name" class="col-12 mb-2 md:col-2 md:mb-0" i18n> Name</label>
    <div class="col-12 md:col-10">
      <input id="organization-name" type="text" pInputText [(ngModel)]="organization.name" ngDefaultControl>
    </div>
  </div>

  <div class="field grid">
    <label for="organization-address" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Address</label>
    <div class="col-12 md:col-10">
      <input id="organization-address" type="text" pInputText [(ngModel)]="organization.address" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="organization-zip" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Zip</label>
    <div class="col-12 md:col-10">
      <input id="organization-zip" type="text" pInputText [(ngModel)]="organization.zip" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="organization-city" class="col-12 mb-2 md:col-2 md:mb-0" i18n>City</label>
    <div class="col-12 md:col-10">
      <input id="organization-city" type="text" pInputText [(ngModel)]="organization.city" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <div class="col-offset-3 col-3">
      <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save(organization)"/>
    </div>
    <div class="col-3">
      <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
    </div>
  </div>
</div>
