import { inject, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, combineLatest, defaultIfEmpty, EMPTY, forkJoin, map, mergeMap, Observable, of, shareReplay, take } from 'rxjs';
import { Page } from '@typedefs/page';
import { environment } from '@environments/environment';
import { Pagination } from './pagination';
import { Stock } from '@model/stock';
import { WarehouseService } from './warehouse.service';
import { StockSearch } from '@model/search/stock-search';
import { ArticleService } from './article.service';
import { StockDto, StockGroupDto, StockPalletSummaryDto, StockSummaryDto } from '@typedefs/stock-rest';
import { CompanyService } from '@services/company.service';
import { SupplierService } from '@services/supplier.service';
import { StockPalletSummary } from '@model/stock-pallet-summary';
import { StockPalletService } from '@services/stock-pallet.service';
import { Zone } from '@model/zone';
import { ZoneService } from "@services/zone.service";
import { StockPallet } from "@model/stock-pallet";
import { PreparationStockSelection } from "@model/preparation-stock-selection";
import { StockSummary } from "@model/stock-summary";
import { rxResource, toObservable } from "@angular/core/rxjs-interop";
import { StockGroup } from "@model/stock-group";
import { StockSummaryService } from "@services/stock-summary.service";
import { StockSearchService } from "@services/stock-search.service";
import { StockGroupService } from "@services/stock-group.service";
import { FoodbankCacheFactory } from "@services/foodabank-cache-factory";

type StockPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class StockService {

  #httpClient = inject(HttpClient);
  #warehouseService = inject(WarehouseService);
  #companyService = inject(CompanyService);
  #articleService = inject(ArticleService);
  #supplierService = inject(SupplierService);
  #palletService = inject(StockPalletService);
  #stockSummaryService = inject(StockSummaryService);
  #stockSearchService = inject(StockSearchService);
  #stockGroupService = inject(StockGroupService);
  #zoneService = inject(ZoneService);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);

  getStock$(stockId: number, injector: Injector): Observable<Stock> {
    return this.#httpClient.get<StockDto>(`${environment.apiUrl}/stocks/${stockId}`)
      .pipe(
        map(stockDto => this.mapToStock(stockDto, injector)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
  }

  getStockByPallet$(pallet: StockPallet, injector: Injector): Observable<Stock | undefined> {
    return this.#httpClient.get<StockDto>(`${environment.apiUrl}/pallets/${pallet.id}/stock`)
      .pipe(
        map(stockDto => this.mapToStock(stockDto, injector)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of(undefined);
          }
          // Rethrow the error for other error status codes
          throw error;
        }),
        shareReplay(),
      );
  }

  findStock(stockSearch: StockSearch, pagination: StockPagination, injector: Injector): Observable<Page<Stock>> {
    const stockSearchDto = this.#stockSearchService.mapToStockSearchDto(stockSearch);
    return this.#httpClient.post<Page<StockDto>>(`${environment.apiUrl}/stocks/search`, stockSearchDto, {params: pagination})
      .pipe(map(stockPage => {
        const stockList: Stock[] = stockPage.content.map(stock => this.mapToStock(stock, injector));
        return {
          ...stockPage,
          content: stockList
        }
      }));
  }

  getStockSummary$(stockSearch: StockSearch, injector: Injector): Observable<StockSummary> {
    const stockSearchDto = this.#stockSearchService.mapToStockSearchDto(stockSearch);

    return this.#httpClient.post<StockSummaryDto>(`${environment.apiUrl}/stocks/search/summary`, stockSearchDto)
      .pipe(
        map(stockSummaryDto => this.#stockSummaryService.mapToStockSummary(stockSummaryDto)),
      );
  }


  #loadArticle$(articleId: string) {
    return this.#articleService.getArticle$(articleId);
  }

  #loadWarehouse$(warehouseId: number) {
    return this.#warehouseService.getWarehouse$(warehouseId);
  }

  #loadCompany$(companyId: string) {
    return this.#companyService.getCompany$(companyId);
  }

  #loadSupplier$(supplierId: string) {
    return this.#supplierService.getSupplier$(supplierId);
  }

  #loadPallet$(palletId: number) {
    return this.#palletService.getStockPallet$(palletId);
  }

  #loadPalletSummary$(stockId: number): Observable<StockPalletSummary> {
    return this.#httpClient.get<StockPalletSummaryDto>(`${environment.apiUrl}/stocks/${stockId}/pallet-summary`)
      .pipe(
        map(stockDto => this.mapToStockPalletSummary(stockDto)),
        shareReplay()
      );
  }

  #loadPreparationZone$(preparationZoneId?: string): Observable<Zone | undefined> {
    return preparationZoneId ? this.#zoneService.find$(preparationZoneId) : of(undefined);
  }

  #loadStockGroup(stockGroupId: number, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<StockGroup> {
    return this.#httpClient.get<StockGroupDto>(`${environment.apiUrl}/stocks/groups/${stockGroupId}`)
      .pipe(
        map(stockGroupDto => this.#stockGroupService.mapToStockGroup(stockGroupDto, cache)),
      );
  }

  mapToStockPalletSummary(stockPalletSummaryDto: StockPalletSummaryDto): StockPalletSummary {
    return {
      ...stockPalletSummaryDto,
    };
  }

  mapToStock(stockDto: StockDto, injector: Injector): Stock {
    return {
      ...stockDto,
      article$: this.#loadArticle$(stockDto.articleId),
      warehouse$: this.#loadWarehouse$(stockDto.warehouseId),
      company$: this.#loadCompany$(stockDto.companyId),
      supplier$: this.#loadSupplier$(stockDto.supplierId),
      pallet$: this.#loadPallet$(stockDto.palletId),
      palletSummary$: this.#loadPalletSummary$(stockDto.id),
      preparationZone$: this.#loadPreparationZone$(stockDto.preparationZoneId),
      stockGroup: rxResource({loader: () => this.#loadStockGroup(stockDto.stockGroupId, injector), injector})
    };
  }

  mapToStockDto(stock: Stock): Observable<StockDto> {
    const preparationZone$ = stock.preparationZone$.pipe(defaultIfEmpty(undefined));
    return combineLatest([stock.pallet$, stock.warehouse$, stock.company$, preparationZone$, toObservable(stock.stockGroup.value)])
      .pipe(map(([pallet, warehouse, company, preparationZone, stockGroup]) =>
        ({
          id: stock.id,
          stockGroupId: stockGroup?.id || -1,
          palletId: pallet?.id,
          receptionDate: stock.receptionDate,
          quantity: stock.quantity,
          expirationDate: stock.expirationDate,
          bestBeforeDate: stock.bestBeforeDate,
          deliverBeforeDate: stockGroup?.deliverBeforeDate as Date,
          unitWeight: stock.unitWeight,
          fullDescription: stockGroup?.fullDescription || '',
          unitsPerParcel: stock.unitsPerParcel,
          unitGrossWeight: stock.unitGrossWeight,
          sourceRecept: stock.sourceRecept,
          location: stock.location,
          preparationZoneId: preparationZone?.location,
          temporary: stock.temporary,
          articleId: stockGroup?.article.value()?.id || '',
          warehouseId: warehouse?.id,
          companyId: company?.id,
          supplierId: stockGroup?.supplier.value()?.id  || '',
          internalBatch: stock.internalBatch,
          supplierBatch: stock.supplierBatch,
        })));
  }


  moveStockOutOfPreparationZone(stockToMove: PreparationStockSelection[], injector: Injector) {
    const movedStocks = stockToMove
      .map(stock =>
        stock.stock$.pipe(take(1), map(stock => {
          stock.preparationZone$ = EMPTY;
          stock.temporary = false;

          return stock;
        })));

    return forkJoin(movedStocks).pipe(
      mergeMap(stocks => this.#saveAllStocks(stocks, injector))
    );
  }

  #saveAllStocks(stocks: Stock[], injector: Injector): Observable<Stock[]> {
    const stockDtoUpdateRequests$ = stocks.map(stock => this.#saveStock(stock, injector));
    return forkJoin(stockDtoUpdateRequests$);
  }

  #saveStock(stock: Stock, injector: Injector): Observable<Stock> {
    const stockDto$ = this.mapToStockDto(stock);
    return stockDto$.pipe(
      mergeMap(stockDto => this.#saveStockDto(stockDto)),
      map(stockDto => this.mapToStock(stockDto, injector))
    );
  }

  #saveStockDto(stockDto: StockDto): Observable<StockDto> {
    return this.#httpClient.put<StockDto>(`${environment.apiUrl}/stocks/${stockDto.id}`, stockDto);
  }
}
