import { Component, Input, model } from '@angular/core';
import {Returnable} from '@model/returnable';
import {map} from 'rxjs';
import {LanguageService} from '@services/language.service';
import { NgIf, AsyncPipe } from '@angular/common';
import { LanguageDirective } from '../../directives/language.directive';

@Component({
    selector: 'foodbank-returnable',
    templateUrl: './returnable.component.html',
    styleUrls: ['./returnable.component.scss'],
    imports: [NgIf, LanguageDirective, AsyncPipe]
})
export class ReturnableComponent {
  ngModel = model<Returnable>()

  @Input()
  showId = true;

  showId$ = this.languageService.isCurrentLanguageDisplayable$()
    .pipe(map(isDisplayable => {
      if (!isDisplayable) {
        return true;
      } else {
        return this.showId;
      }
    }));

  constructor(private languageService: LanguageService) {
  }

}
