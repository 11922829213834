import { Component, inject, Injector, Input, OnChanges, OnInit } from '@angular/core';
import {PreparationItemSearch} from '@model/search/preparation-item-search';
import {PreparationItemService} from '@services/preparation-item.service';
import {PaginationService} from '@services/pagination.service';
import {PreparationItem} from '@model/preparation-item';
import {BehaviorSubject, combineLatest, distinctUntilChanged, forkJoin, map, Observable, shareReplay, switchMap} from 'rxjs';
import {Organization} from '@model/organization';
import {Company} from '@model/company';
import {Article} from '@model/article';
import {Preparation} from '@model/preparation';
import {NgIf, NgFor, AsyncPipe, DatePipe} from '@angular/common';
import { WarehouseComponent } from '../../../../warehouse/warehouse.component';
import { FormsModule } from '@angular/forms';
import { OrganizationComponent } from '../../../../organization/organization.component';
import { ArticleComponent } from '../../../../article/article.component';
import { NumberComponent } from '../../../../number/number.component';
import { ProgressSpinnerModule } from "primeng/progressspinner";

interface PreparationLabelGroup {
  organization: Organization;
  company: Company;
  article: Article;
  preparation: Preparation;
  preparationItem: PreparationItem;
}

@Component({
    selector: 'fead-preparation-labels-report',
    templateUrl: './fead-preparation-labels.component.html',
    styleUrls: ['./fead-preparation-labels.component.scss'],
    imports: [NgIf, NgFor, WarehouseComponent, FormsModule, OrganizationComponent, ArticleComponent, NumberComponent, AsyncPipe, ProgressSpinnerModule, DatePipe]
})
export class FeadPreparationLabelsComponent implements OnInit, OnChanges {

  @Input()
  preparationItemSearch!: PreparationItemSearch;
  showBarCode = true;

  preparationItemSearchSubject$!: BehaviorSubject<PreparationItemSearch>;

  preparationItems$!: Observable<PreparationItem[]>;
  groups$!: Observable<PreparationLabelGroup[]>;

  private preparationItemService = inject(PreparationItemService);
  private paginationService = inject(PaginationService);
  #injector = inject(Injector);

  constructor() {
  }

  ngOnInit(): void {
    this.preparationItemSearchSubject$ = new BehaviorSubject<PreparationItemSearch>(this.preparationItemSearch);
    const reportPagination = this.paginationService.getReportPagination();
    this.preparationItems$ = this.preparationItemSearchSubject$.pipe(
      distinctUntilChanged(this.isSameSearch),
      switchMap(preparationItemSearch => this.preparationItemService.find(preparationItemSearch, reportPagination, this.#injector)),
      map(page => page.content),
      shareReplay(),
    );
    this.groups$ = this.preparationItems$.pipe(
      switchMap(preparationItems => this.splitPreparationItems(preparationItems)),
      shareReplay(),
    );
  }

  private splitPreparationItems(preparationItems: PreparationItem[]): Observable<PreparationLabelGroup[]> {
    const splittedPreparationItems: Observable<PreparationLabelGroup>[] = preparationItems.map(preparationItem => this.splitPreparationItem$(preparationItem));
    return forkJoin(splittedPreparationItems);
  }

  private isSameSearch(search1: PreparationItemSearch, search2: PreparationItemSearch): boolean {
    const json1 = JSON.stringify(search1);
    const json2 = JSON.stringify(search2);
    return json1===json2;
  }

  private splitPreparationItem$(preparationItem: PreparationItem): Observable<PreparationLabelGroup> {
    const organization$ = preparationItem.organization$;
    const article$ = preparationItem.article$;
    const preparation$ = preparationItem.preparation$;

    return combineLatest([organization$, article$, preparation$]).pipe(
      switchMap(([organization, article, preparation]) => preparation.company$.pipe(
        map(company => ({
          organization: organization,
          company: company,
          article: article,
          preparation: preparation,
          preparationItem: preparationItem
        }) as PreparationLabelGroup)
      )),
    );
  }

  ngOnChanges() {
    if (this.preparationItemSearchSubject$) {
      this.preparationItemSearchSubject$.next(this.preparationItemSearch);
    }
  }

}
