import { Injectable, Injector } from "@angular/core";
import { read, utils } from "xlsx";
import { Article } from "@model/article";
import { ArticleService } from "./article.service";
import { rxResource } from "@angular/core/rxjs-interop";
import { of } from "rxjs";
import { ResourceRef } from "@angular/core";

// Excel Column Mappings
const EXCEL_COLUMNS = {
  FOOD_ID: 'FoodID',
  YEAR_MONTH: 'JaarMaand',
  SUPPLIER_NAME: 'Oorspong::Naam',
  SUPPLIER_ID: 'Oorsprong::FoodID',
  SUPPLIER_POSTAL_CODE: 'Oorsprong::Postcode',
  SUPPLIER_CITY: 'Oorsprong::Gemeente',
  PRODUCT_CODE: 'Productcode',
  PRODUCT_ARTICLE: 'Producten::Artikel',
  PRODUCT_UNIT: 'Producten::Eenheid',
  PRODUCT_QUANTITY: 'R_AantalInop Productcode'
} as const;

export interface ImportRow {
  rowNumber: number;
  yearMonth: number;
  supplierName: string;
  supplierId: string;
  postalCode: string;
  city: string;
  productCode: string;
  productId: string;
  article: string;
  unit: string;
  quantity: number;
  articleRef: ResourceRef<Article | undefined>;
}

interface ExcelRow {
  __rowNum__: number;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class BulkFoodImportService {
  constructor(
    private articleService: ArticleService
  ) {}

  async importExcelFile(file: File, injector: Injector): Promise<ImportRow[]> {
    const excelRows = await this.#readExcel(file);
    return this.#mapExcelToImportRows(excelRows, injector);
  }

  async #readExcel(file: File): Promise<ExcelRow[]> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          const bufferArray = e.target?.result;
          const wb = read(bufferArray, {type: "buffer"});
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          const data = utils.sheet_to_json(ws);
          resolve(data.map((row: any, index: number) => ({ ...row, __rowNum__: index + 1 })));
        } catch (error) {
          reject(error);
        }
      };
    });
  }

  #mapExcelToImportRows(data: ExcelRow[], injector: Injector): ImportRow[] {
    return data.map(item => {
      const productId = item[EXCEL_COLUMNS.FOOD_ID];
      return {
        rowNumber: item.__rowNum__,
        yearMonth: item[EXCEL_COLUMNS.YEAR_MONTH],
        supplierName: item[EXCEL_COLUMNS.SUPPLIER_NAME],
        supplierId: item[EXCEL_COLUMNS.SUPPLIER_ID],
        postalCode: item[EXCEL_COLUMNS.SUPPLIER_POSTAL_CODE],
        city: item[EXCEL_COLUMNS.SUPPLIER_CITY],
        productCode: item[EXCEL_COLUMNS.PRODUCT_CODE],
        productId,
        article: item[EXCEL_COLUMNS.PRODUCT_ARTICLE],
        unit: item[EXCEL_COLUMNS.PRODUCT_UNIT],
        quantity: item[EXCEL_COLUMNS.PRODUCT_QUANTITY],
        articleRef: rxResource<Article | undefined, Error>({
          loader: () => productId ? this.articleService.getArticle$(productId) : of(undefined),
          injector
        })
      };
    });
  }
} 