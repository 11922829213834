<div class="card p-fluid" *ngIf="member() as member">

  <div class="field grid">
    <label for="member-familyName" class="col-12 mb-2 md:col-2 md:mb-0" i18n> FamilyName</label>
    <div class="col-12 md:col-10">
      <input id="member-familyName" type="text" pInputText [(ngModel)]="member.familyName" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="member-firstName" class="col-12 mb-2 md:col-2 md:mb-0" i18n>First Name</label>
    <div class="col-12 md:col-10">
      <input id="member-firstName" type="text" pInputText [(ngModel)]="member.firstName" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="organization" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Organization</label>
    <div class="col-12 md:col-10">
      <foodbank-organization-selection ngDefaultControl id="organization" [(ngModel)]="member.organization.value" />
    </div>
  </div>
  <div class="field grid">
    <label for="role" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Role</label>
    <div class="col-12 md:col-10">
      <foodbank-role-selection ngDefaultControl id="role" [(ngModel)]="member.role.value" />
    </div>
  </div>
  <div class="field grid">
    <label for="member-address" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Address</label>
    <div class="col-12 md:col-10">
      <input id="member-address" type="text" pInputText [(ngModel)]="member.address" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <div class="col-offset-3 col-3">
      <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="save(member)"/>
    </div>
    <div class="col-3">
      <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
    </div>
  </div>
</div>

