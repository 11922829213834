import {Component, Input} from '@angular/core';
import {map} from 'rxjs';
import {LanguageService} from '@services/language.service';
import {ArticleStorageConditionDto} from '@typedefs/stock-rest';
import {AsyncPipe, NgIf} from "@angular/common";
import {LanguageDirective} from "@directives/language.directive";

@Component({
    selector: 'foodbank-article-storage-condition',
    templateUrl: './article-storage-condition.component.html',
    styleUrls: ['./article-storage-condition.component.scss'],
    imports: [
        AsyncPipe,
        LanguageDirective,
        NgIf
    ]
})
export class ArticleStorageConditionComponent {

  @Input()
  articleStorageCondition: ArticleStorageConditionDto | undefined | null;

  @Input()
  showId: boolean = true;

  showId$ = this.languageService.isCurrentLanguageDisplayable$()
    .pipe(map(isDisplayable => {
      if (!isDisplayable) {
        return true;
      } else {
        return this.showId;
      }
    }));

  constructor(private languageService: LanguageService) {
  }
}
