import {inject, Injectable, Injector, Signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserDto, UserSearchDto} from '@typedefs/stock-rest';
import {distinctUntilChanged, filter, map, mergeMap, Observable, of, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {UserSearch} from '@model/search/user-search';
import {Pagination} from './pagination';
import {User, UserS} from '@model/user';
import {WarehouseService} from './warehouse.service';
import {OrganizationService} from '@services/organization.service';
import {CompanyService} from '@services/company.service';
import {isSameUser} from "@model/comparator/user-comparator";
import {AuthenticationService} from "@services/authentication.service";
import {toSignal} from "@angular/core/rxjs-interop";
import {Company} from "@model/company";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {FoodbankCache} from "@services/foodabank-cache";
import {copyCommonFields} from "@model/mapping-utils";

type UserPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private readonly currentUser$: Observable<User>;

  #httpClient = inject(HttpClient);
  #authenticationService = inject(AuthenticationService);
  #warehouseService = inject(WarehouseService);
  #organizationService = inject(OrganizationService);
  #companyService = inject(CompanyService);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);

  constructor() {
    this.currentUser$ = this.#authenticationService.isReady$()
      .pipe(
        filter(ready => ready),
        mergeMap(_ => this.#loadCurrentUser$()),
        distinctUntilChanged(isSameUser),
        shareReplay(), // removing this one seems to break stuff in the reception edit dialog
      );
  }

  getUser$(id: string) {
    return this.#httpClient.get<UserDto>(`${environment.apiUrl}/users/${id}`).pipe(
      map(userDto => this.#legacyLoadUser(userDto)),
      shareReplay()
    );
  }

  getUserS$(id: string, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)) {
    return this.#httpClient.get<UserDto>(`${environment.apiUrl}/users/${id}`).pipe(
      map(userDto => this.#mapToUser(userDto, cache)),
      shareReplay()
    );
  }

  findUsers(userSearch: UserSearch, pagination?: UserPagination): Observable<Page<User>> {
    return this.#httpClient.post<Page<UserDto>>(`${environment.apiUrl}/users/search`, this.mapToUserSearchDto(userSearch), {params: pagination})
      .pipe(
        map(usersPage => {
          const users: User[] = usersPage.content.map(userDto => this.#legacyLoadUser(userDto));
          return {
            ...usersPage,
            content: users
          }
        }),
        shareReplay(),
      );
  }

  #loadCurrentUser$() {
    return this.#httpClient.get<UserDto>(`${environment.apiUrl}/users/me`).pipe(
      map(userDto => this.#legacyLoadUser(userDto)),
    );
  }

  getCurrentUser$(): Observable<User> {
    return this.currentUser$;
  }

  getCurrentUser(): Signal<User | undefined> {
    return toSignal(this.currentUser$);
  }

  getCurrentUserPreferences<T>(key: string, initialValue: T): Signal<T> {
    const observable = this.currentUser$.pipe(
      filter(user => !!user),
      map(user => localStorage.getItem(user.id + '_' + key)),
      map(value => value ? JSON.parse(value) : initialValue),
    );

    return toSignal(observable, {initialValue});
  }

  setUserPreferences<T>(user: User, key: string, preferences: T) {
    localStorage.setItem(user.id + '_' + key, JSON.stringify(preferences));
  }

  getCurrentUserCompany$() {
    return this.currentUser$.pipe(
      mergeMap(user => user.company$),
      shareReplay()
    );
  }

  getCurrentUserCompany(): Signal<Company | undefined> {
    const currentUserCompany$ = this.getCurrentUserCompany$();
    return toSignal(currentUserCompany$);
  }

  getDefaultWarehouse$() {
    return this.currentUser$.pipe(
      mergeMap(user => user.warehouse$),
      shareReplay()
    );
  }

  mapToUserSearchDto(userSearch: UserSearch): UserSearchDto {
    return {
      id: userSearch.id,
      userName: userSearch.userName,
      companyId: userSearch.company?.id,
      warehouseSearchDto: userSearch.warehouseSearch ? this.#warehouseService.mapToWarehouseSearchDto(userSearch.warehouseSearch) : undefined
    }
  }

  /**
   * @deprecated use loadUserS
   */
  #legacyLoadUser(userDto: UserDto): User {
    const company$ = this.#companyService.getCompany$(userDto.companyId);

    const userWarehouse$ = of(userDto.warehouseId).pipe(mergeMap(warehouseId => warehouseId ? this.#warehouseService.getWarehouse$(userDto.warehouseId) : of(undefined)));
    const companyWarehouse$ = company$.pipe(mergeMap(company => company.warehouse$));
    const userOrCompanyWarehouse$ = userWarehouse$.pipe(
      mergeMap(userWarehouse => userWarehouse ? of(userWarehouse) : companyWarehouse$)
    );

    const organization$ =
      userDto.organizationId ? this.#organizationService.getOrganization$(userDto.organizationId) : undefined;
    return {
      ...userDto,
      company$,
      warehouse$: userOrCompanyWarehouse$,
      organization$: organization$,
    };
  }

  #mapToUser(userDto: UserDto, cache: FoodbankCache): UserS {
    const commonFields: UserS | UserDto = copyCommonFields(userDto, ['warehouseId', 'companyId', 'organizationId']);

    return {
      ...commonFields,
      company: cache.companyCache.get(userDto.companyId),
      warehouse: cache.warehouseCache.getIfDefined(userDto.warehouseId),
      organization: cache.organizationCache.getIfDefined(userDto.organizationId),
    };
  }

}
